import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';

import {
  TextField,
  MenuItem,
} from '@material-ui/core';
import Switch from 'components/FormInputs/Switch';
import { DatePicker } from "@material-ui/pickers";
import IconCard from 'components/IconCard';
import { useForm, Controller } from "react-hook-form";
import {AiOutlineFileSearch} from 'react-icons/ai';

interface IIdentification {
  readOnly?: boolean,
  defaultValues?: any,
  onSubmit?: Function
}

const Identification: React.FC<IIdentification> = (props) => {

  const {
    defaultValues,
    readOnly,
    onSubmit,
  } = props;


  const { register, handleSubmit, errors, formState, reset, control } = useForm({
    mode: 'onChange',
    defaultValues,
  });


  const [ loading, setLoading] = useState(false);

  const submit = async (data: any) => {
    setLoading(true);
    if (onSubmit) {
      try {
        const response = await onSubmit(data);
        reset(response);
      }
      catch(error){
        alert('error');
      }
      finally{
        setLoading(false);
      }
    }
  }

  const options = {
    type: ['Personal', 'Business'],
    size: [
      'Micro (1-4 Staff)', 
      'Small (5-14 Staff)', 
      'Medium (15-49 Staff)',
      'Large (50-99 Staff)',
      'Corporate (100-499 Staff)',
      'Enterprise (500 + Staff)'
    ],
    biologicalSex: ['Male', 'Female'],
  };

  const fieldSize = 'small';
  return (
    <IconCard 
      icon={<AiOutlineFileSearch />}
      iconColor="info"
      title="Identification"
      readOnly={readOnly}
      loading={loading}
      isDirty={formState.isDirty}
      resetFunction={ reset }
      saveFunction={ () =>  handleSubmit(submit)() }
    >
      <form onSubmit={handleSubmit(submit)}>
        <Grid container spacing={2}>

          <Grid item xs={12} sm={6}>
            <TextField 
              variant="outlined"
              fullWidth
              label="First Name"
              size={fieldSize}
              inputRef={register}
              name="identification.firstName"
              error={errors?.identification?.firstName ? true : false}
              helperText={errors?.identification?.firstName?.message}
              InputProps={{ readOnly }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField 
              variant="outlined"
              fullWidth
              label="Last Name"
              size={fieldSize}
              inputRef={register}
              name="identification.lastName"
              error={errors?.identification?.lastName ? true : false}
              helperText={errors?.identification?.lastName?.message}
              InputProps={{ readOnly }}
            />
          </Grid>


          <Grid item xs={12} sm={4}>
            <Controller
              as={DatePicker}
              control={control}
              value={null}
              defaultValue={null}
              onChange={(data: any) => data}
              renderInput={(props: any) => 
                <TextField 
                  {...props} 
                  variant="outlined" 
                  label="Birthday" 
                  fullWidth 
                  size={fieldSize}
                  error={errors?.identification?.birthday ? true : false}
                  helperText={errors?.identification?.birthday?.message}
                />}
              name="identification.birthday"
              readOnly={readOnly}
            />
          </Grid>
          
         

          <Grid item xs={12} sm={3}>
            <Controller 
              select
              label="Gender"
              control={control}
              variant="outlined"
              InputProps={{ readOnly }}
              name="identification.gender"
              error={errors?.identification?.gender ? true : false}
              helperText={errors?.identification?.gender?.message}
              fullWidth
              size={fieldSize}
              defaultValue=""
              as={
                <TextField>
                  {/* <MenuItem value=""></MenuItem> */}
                  {options.biologicalSex.map((item) => (
                    <MenuItem key={item} value={item}>{item}</MenuItem>
                  ))}
                </TextField>
              }
            />
          </Grid>

          <Grid item xs={12} sm={4} >
            <Switch 
              control={control}
              defaultValue={false}
              readOnly={readOnly}
              name="identification.active"
              label='Active'
            />
          </Grid>


        </Grid>

      </form>

    </IconCard>
  )
}

export default Identification;