import React, {useContext} from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Drawer from 'components/Drawer';
import {MainContext} from 'context/MainContext';
import {routes, sidenavRoutes} from 'routes/routes';
import {MdBrightnessMedium, MdBrightnessHigh} from 'react-icons/md';
import Toolbar from 'components/Toolbar';


import styles from './styles';
const useStyles = makeStyles(styles);


const Layout: React.FC = () => {
  const classes = useStyles();
  const {theme, setTheme} = useContext(MainContext);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const switchRoutes = (
    <Switch>
      {routes.map((prop, key) => {
        return (
          <Route
            exact
            path={prop.path}
            key={key}
          >
            {prop.component}
          </Route>
        );
      })}
      {sidenavRoutes.map((prop, key) => {
        return (
          <Route
            exact
            path={prop.path}
            key={key}
          >
            {prop.component}
          </Route>
        );
      })}
      <Redirect from="/" exact to="/dashboard" />
    </Switch>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar 
        color="info" 
        handleDrawerToggle={() => {setMobileOpen(!mobileOpen)}}
        links={[<IconButton style={{color: 'white'}} key="1" onClick={() => {
          if (theme === 'dark'){
            setTheme('ligth')
          }
          else{
            setTheme('dark')
          }
          
        }}>{theme === 'dark' ? <MdBrightnessHigh /> : <MdBrightnessMedium/>}</IconButton>]}
      />

      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer 
            logo="/assets/img/logo.png"
            bgImage="/assets/img/sidebar-4.jpg"
            color="blue"
            routes={sidenavRoutes}
            open={mobileOpen}
            handleDrawerToggle={() => {setMobileOpen(false);}}
          />
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {switchRoutes}
      </main>
    </div>
  );
}

export default Layout;
