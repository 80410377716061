import React from 'react';
import Button from "@material-ui/core/Button";
import { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import styles from './styles';

const useStyles = makeStyles(styles);

type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

interface ICustomButton {
  color?: "transparent" | "white" | "rose" | "primary" | "info" | "success" | "warning" | "danger",
  size?: "sm" | "lg",
  simple?: boolean,
  round?: boolean,
  disabled?: boolean,
  block?: boolean,
  link?: boolean,
  justIcon?: boolean,
  className?: string,
  component?: React.ReactNode,
  to?: React.ReactNode
}

export interface ButtonExtended extends Overwrite<ButtonProps, ICustomButton>{}

const CustomButton: React.FC<ButtonExtended> = (props) => {
  const classes = useStyles();

  const {
    color, 
    size,
    simple,
    round,
    disabled,
    block,
    link,
    justIcon,
    className,
    children, 
    ...rest
  } = props;

    let btnClasses = clsx({
      [classes.button]: true,
      [classes.round]: round,
      [classes.disabled]: disabled,
      [classes.simple]: simple,
      [classes.block]: block,
      [classes.link]: link,
      [classes.justIcon]: justIcon
    });

    if (color !== undefined) {
      btnClasses += " " + clsx(classes[color], true);
    }

    if (size !== undefined) {
      btnClasses += " " + clsx(classes[size], true);
    }

  return (
    <Button {...rest} className={btnClasses}>
      {children}
    </Button>
  )
}

export default CustomButton;