import React from 'react';

import {
  FcViewDetails, 
  FcContacts,
  FcFactory,
  FcVlc,
  // FcGrid,
  FcCalendar
} from 'react-icons/fc';

import {GoDashboard} from 'react-icons/go';

// Components
import Dashboard from 'screens/Dashboard';
import AgreementTable from 'screens/Agreement/AgreementTable';
import AgreementForm  from 'screens/Agreement/AgreementForm';
import Calendar  from 'screens/Calendar';
import AgreementServiceForm from 'screens/AgreementService/AgreementServiceForm';
import AgreementServicesTable from 'screens/AgreementService/AgreementServiceTable';
import Contact from 'screens/Contact/ContactTable';
import ContactForm from 'screens/Contact/ContactForm';
import Account from 'screens/Account/AccountTable';
import AccountForm from 'screens/Account/AccountForm';
import Profile from 'screens/Profile';
// import TableTest from 'screens/TableTest';

const sidenavRoutes = [
  {
    component: <Dashboard />, 
    path: '/dashboard', 
    name: 'Dashboard', 
    icon: <GoDashboard size="30"/>
  },
  {
    component: <Calendar />, 
    path: '/calendar', 
    name: 'Calendar', 
    icon:<FcCalendar size="30"/>
  },
  {
    component: <Account />, 
    path: '/account', 
    name: 'Account', 
    icon:<FcFactory size="30"/>
  },
  {
    component: <Contact />, 
    path: '/contact', 
    name: 'Contact', 
    icon:<FcContacts size="30"/>
  },
  {
    component: <AgreementTable />, 
    path: '/agreement', 
    name: 'Agreement', 
    icon:<FcViewDetails size="30"/>
  },
  {
    component: <AgreementServicesTable />, 
    path: '/agreement-services', 
    name: 'Agreement Service', 
    icon:<FcVlc size="30"/>
  },
  // {
  //   component: <TableTest />, 
  //   path: '/table-test', 
  //   name: 'Table Test', 
  //   icon: <FcGrid size="30"/>
  // },
]

const routes = [
  {
    component: <AgreementForm />, 
    path: '/agreement/:action/:agreementId', 
    name: 'Agreement Form', 
    icon:<FcViewDetails 
    size="30"/>
  },
  {
    component: <AgreementServiceForm />,   
    path: '/agreement/:action/:agreementId/service/:serviceId', 
    name: 'Agreement Service Form', 
    icon:<FcViewDetails
    size="30"/>
  },
  {
    component: <AccountForm />, 
    path: '/account/:action/:accountId', 
    name: 'Account Form', 
    icon:<FcViewDetails 
    size="30"/>
  },
  {
    component: <ContactForm />, 
    path: '/contact/:action/:contactId', 
    name: 'Contact Form', 
    icon:<FcViewDetails 
    size="30"/>
  },
  {
    component: <Profile />,   
    path: '/profile', 
    name: 'Profile', 
    icon:<FcViewDetails 
    size="30"/>
  },
]

export { routes, sidenavRoutes }

export default routes;