import React from 'react';
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import styles from './styles';

const useStyles = makeStyles(styles);

interface ICard {
  className?: string,
  color? : "warning" | "success" | "danger" | "info" | "primary" | "rose",
  plain? : boolean,
  stats?: boolean,
  icon?: boolean,
}

const CustomCard: React.FC<ICard> = (props) => {
  const classes = useStyles();

  const {
    className, 
    color,
    plain,
    stats,
    icon,
    children,
    ...rest
  } = props;

  let cardClasses = clsx({
    [classes.cardHeader]: true,
    [classes.cardHeaderPlain]: plain,
    [classes.cardHeaderStats]: stats,
    [classes.cardHeaderIcon]: icon,
  });

  if (color !== undefined) {
    cardClasses += ' ' + clsx(classes[color], true);
  }

  if (className) {
    cardClasses += ' ' + clsx(className, true);
  }


  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  )
}

export default CustomCard;