import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';

import  { useParams } from 'react-router-dom';

import Api from 'Services/api';
// import { useForm } from "react-hook-form";
import LinearProgress from '@material-ui/core/LinearProgress';

import IdentificationBlock from './blocks/Identification';
import CommunicationBLock from '../../../sharedBlocks/Communication';
import AddressBlock from '../../../sharedBlocks/Addresses';
// const useStyles = makeStyles(styles);

interface ContactParams {
  contactId: string;
  action: string;
}

const ContactForm: React.FC = () => {
  // const classes = useStyles();

  const { contactId, action } = useParams<ContactParams>();

  const [ contact, setContacts ] = useState<any>(undefined); 
  const [ isLoading, setIsLoading ] = useState(true);


  useEffect(() => {
    async function getContacts(){
      try {
        const response = await Api.get(`/contact/${contactId}`);
        console.log(response.data);
        setContacts(response.data);
        setIsLoading(false);
      }
      catch(error){
        alert('error on get contact');
      }
    }
    getContacts();
  }, [contactId]);


  const onSubmit = async (data: any) => {
    const response = await Api.put(`/contact/${contactId}`, data);
    setContacts(response.data);
    return(response.data);
  };

  const readOnly = (action === 'view') ? true: false;

  return (
    <Grid container spacing={2}>
      {isLoading ?
        <Grid item sm={12}>
          <LinearProgress />
        </Grid>
      : 
      <>
      
        <IdentificationBlock 
          readOnly={readOnly}
          defaultValues={{identification: contact.identification}}
          onSubmit={onSubmit}
        />

        <CommunicationBLock 
          readOnly={readOnly}
          defaultValues={{communication: contact.communication}}
          onSubmit={onSubmit}
        />

        <AddressBlock 
          readOnly={readOnly}
          defaultValues={{addresses: contact.addresses}}
          onSubmit={onSubmit}
        />

      </>
      }

    </Grid>
  )
}

export default ContactForm;
