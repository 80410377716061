import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';

// import { makeStyles } from '@material-ui/core/styles';

// import {MdClose, MdEdit, MdRemoveRedEye, MdAdd, MdDelete} from 'react-icons/md';
// import {RiAddLine} from 'react-icons/ri';
// import SvgIcon from '@material-ui/core/SvgIcon';
// import styles from './styles';
// import {Link} from 'react-router-dom';

import  { useParams } from 'react-router-dom';

import Api from 'Services/api';
// import { useForm } from "react-hook-form";
import LinearProgress from '@material-ui/core/LinearProgress';

import IdentificationBlock from './blocks/Identification';
import AdminFeaturesBlock from './blocks/AdminFeatures';
import BillingFeaturesBlock from './blocks/BillingFeatures';

import OrderingCompanyBlock from './blocks/OrderingCompany';
import ManagingCompayBlock  from './blocks/ManagingCompany';
import BillToBlock          from './blocks/BillTo';

// import ServicesTableBlock from './blocks/ServicesTable';
import ServicesTableBlock from '../AgreementServiceTable';

// const useStyles = makeStyles(styles);

interface AgreementParams {
  agreementId: string;
  action: string;
}

const Agreement: React.FC = () => {
  // const classes = useStyles();

  const { agreementId, action } = useParams<AgreementParams>();

  const [ agreement, setAgreement ] = useState<any>(undefined); 
  const [ isLoading, setIsLoading ] = useState(true);

  // async function getAgreement(){
  //   try {
  //     const response = await Api.get(`/agreement/${agreementId}`);
  //     console.log(response.data);
  //     setAgreement(response.data);
  //     setIsLoading(false);
  //   }
  //   catch(error){
  //     alert('error on get agreement');
  //   }
  // }

  useEffect(() => {
    async function getAgreement(){
      try {
        const response = await Api.get(`/agreement/${agreementId}`);
        console.log(response.data);
        setAgreement(response.data);
        setIsLoading(false);
      }
      catch(error){
        alert('error on get agreement');
      }
    }
    getAgreement();
  }, [agreementId]);


  const onSubmit = async (data: any) => {
    // alert(JSON.stringify(data));
    // return({});
    const response = await Api.put(`/agreement/${agreementId}`, data);
    setAgreement(response.data);
    return(response.data);
  };

  // const xl = 6;
  // const spaceFields = 2;
  // const fieldSize = 'small';
  const readOnly = (action === 'view') ? true: false;

  return (
    <Grid container spacing={2}>
      {isLoading ?
        <Grid item sm={12}>
          <LinearProgress />
        </Grid>
      : 
      <>
      
        <IdentificationBlock 
          readOnly={readOnly}
          defaultValues={{identification: agreement.identification}}
          onSubmit={onSubmit}
        />

        <ManagingCompayBlock 
          readOnly={readOnly}
          defaultValues={{managingCompany: agreement.managingCompany}}
          onSubmit={onSubmit}
        />

        <OrderingCompanyBlock 
          readOnly={readOnly}
          defaultValues={{orderingCompany: agreement.orderingCompany}}
          onSubmit={onSubmit}
        />

        <BillToBlock 
          readOnly={readOnly}
          defaultValues={{billTo: agreement.billTo}}
          onSubmit={onSubmit}
        />

        <AdminFeaturesBlock
          readOnly={readOnly}
          defaultValues={{adminFeatures: agreement.adminFeatures}}
          onSubmit={onSubmit}
        />

        <BillingFeaturesBlock 
          readOnly={readOnly}
          defaultValues={{billingFeatures: agreement.billingFeatures}}
          onSubmit={onSubmit}
        />

        <ServicesTableBlock 
          readOnly={readOnly}
          // defaultValues={{services: agreement.services}}
        />

        
        

      </>
      }


      

    </Grid>
  )
}

export default Agreement;
