import React from 'react';
import { v4 as uuidv4 } from 'uuid';
// import { FixedSizeList as List } from 'react-window';

import {
  Grid,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  makeStyles,
  IconButton
} from '@material-ui/core';

import IconCard from 'components/IconCard';
import { DeleteForever, Edit, RemoveRedEyeOutlined } from '@material-ui/icons';
import { AiOutlineTable } from 'react-icons/ai';
import get from 'lodash.get';
import styles from './styles';
const useStyles = makeStyles(styles);

interface Table {
  color?: 'rose' | 'danger' | 'info' | 'primary' | 'success' | 'warning';
  icon?: React.ReactNode;
  name?: string;
  data?: Array<any>;
  columns?: Array<{label: string; dataKey: string; render?: Function}>;
  addFunction?: Function;
  deleteFunction?: Function;
  viewFunction?: Function;
  editFunction?: Function;
  loading?: boolean;
  buttons?: React.ReactNode;
}

const CustomButton: React.FC<Table> = (props) => {
  const classes = useStyles();

  const {
    color,
    icon,
    name,
    data,
    columns,
    addFunction,
    deleteFunction,
    viewFunction,
    editFunction,
    loading,
    buttons
  } = props;

  const actions =  viewFunction !== undefined  || editFunction !== undefined || deleteFunction !== undefined;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} xl={12}>
        <IconCard 
          loading={loading}
          icon={
            icon ? icon : <AiOutlineTable />
          }
          iconColor={
            color ? color: 'primary'
          }
          title={name}
          buttons={buttons}
          addFunction={addFunction}
        >
          
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns?.map( (column, index) => {
                    return (
                      <TableCell 
                        className={classes.tableHeader} 
                        key={uuidv4()}
                      >
                        {column?.label}
                      </TableCell>
                   ) 
                  })}
                  {actions && 
                    <TableCell align="center" className={classes.tableHeader}>
                      Actions
                    </TableCell>
                  }
                </TableRow>
              </TableHead>
              <TableBody> 

                

                {data?.map( (row, index) => {
                  return (
                    <TableRow key={uuidv4()} >
                      {columns?.map((column, index) => {
                        return (
                          <TableCell key={uuidv4()}>
                              {/* {get(row, column.dataKey)} */}
                              {column.render ? column.render(row)() : get(row, column.dataKey)}
                          </TableCell>
                        )
                      })}
                      {actions &&
                        <TableCell style={{margin: 0, padding: 0, minWidth: 150}} align="center">
                          { viewFunction && 

                          <IconButton
                            onClick={() => {
                              if(viewFunction){
                                viewFunction(row)();
                              }
                            }}
                          >
                              <RemoveRedEyeOutlined style={{color: '#00acc1'}} />
                            </IconButton>

                          }
                          
                          { editFunction &&
                            <IconButton
                              onClick={() => {
                                if(editFunction){
                                  editFunction(row)();
                                }
                              }}
                            >
                              <Edit style={{color: '#4caf50'}} />
                            </IconButton>
                          }

                          { deleteFunction &&
                            <IconButton
                              onClick={() => {
                                if(deleteFunction){
                                  deleteFunction(row)();
                                }
                              }}
                            >
                              <DeleteForever color="error" />
                            </IconButton>
                          }
                            
                            
                          </TableCell>
                      }
                    
                    </TableRow>
                  )
                })}
   
              </TableBody>
            </Table>
          </TableContainer>
        </IconCard>
      </Grid>
    </Grid>
  )
}

export default CustomButton;