import React, { useState } from 'react';
// import Button from "components/Button";
import Grid from '@material-ui/core/Grid';
// import LinearProgress from '@material-ui/core/LinearProgress';

import {
  TextField,
  // Switch,
  // Select,
  // MenuItem,
  // FormControl,
  // InputLabel,
  // FormControlLabel,
  // FormGroup
} from '@material-ui/core';

import Switch from 'components/FormInputs/Switch';

// import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker } from "@material-ui/pickers";

import IconCard from 'components/IconCard';

import { useForm, Controller } from "react-hook-form";

import { AiOutlineCheck} from 'react-icons/ai';

// import { makeStyles } from "@material-ui/core/styles";
// import styles from './styles';
// import { truncateSync } from 'fs';
// const useStyles = makeStyles(styles);

interface IAdminFeatures {
  readOnly?: boolean,
  defaultValues?: any,
  onSubmit?: Function
}

const AdminFeatures: React.FC<IAdminFeatures> = (props) => {
  // const classes = useStyles();

  const {
    defaultValues,
    readOnly,
    onSubmit,
    // ...rest
  } = props;


  const { 
    // register, 
    handleSubmit, 
    errors, 
    formState, 
    reset, 
    control } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  
  const [ loading, setLoading] = useState(false);

  const submit = async (data: any) => {
    if (onSubmit) {
      setLoading(true);
      try {
        const response = await onSubmit(data);
        reset(response);
      }
      catch(error){
        alert('error');
      }
      finally{
        setLoading(false);
      }
    }
  }

  const fieldSize = 'small';
  return (
    <IconCard 
      icon={<AiOutlineCheck />}
      iconColor="danger"
      title="Admin Features"
      loading={loading}
      isDirty={formState.isDirty}
      resetFunction={reset}
      saveFunction={ () => handleSubmit(submit)() }
    >
      <form onSubmit={handleSubmit(submit)}>
        <Grid container spacing={2}>

          <Grid item xs={12} sm={4}>
            <Switch 
              control={control}
              label="Status"
              name="adminFeatures.status"
              readOnly={readOnly}
              // defaultValue={false}
            />
            {/* <FormControlLabel
              labelPlacement="start"
              label="Status"
              control={
                <Controller
                  as={Switch}
                  type="checkbox"
                  name="adminFeatures.status"
                  control={control}
                  defaultValue={false}
                  disabled={readOnly}
                />
              }
            /> */}
          </Grid>

          {/* START DATE */}
          <Grid item xs={12} sm={4}>
            <Controller
              as={DatePicker}
              control={control}
              value={null}
              // defaultValue={null}
              renderInput={(props: any) => 
                <TextField 
                  {...props} 
                  variant="outlined" 
                  label="Start Date" 
                  fullWidth 
                  size={fieldSize}
                  error={errors?.adminFeatures?.startDate ? true : false}
                  helperText={errors?.adminFeatures?.startDate?.message}
                />}
              onChange={(date: any)=> date} //TODO change this
              name="adminFeatures.startDate"
              readOnly={readOnly}
            />
          </Grid>
          
          {/* EXPIRATION DATE */}
          <Grid item xs={12} sm={4}>
            <Controller
              as={DatePicker}
              control={control}
              value={null}
              // defaultValue={null}
              onChange={(date: any)=> date} //TODO change this
              renderInput={(props: any) => 
                <TextField 
                  {...props} 
                  variant="outlined" 
                  label="Expiration Date" 
                  fullWidth 
                  size={fieldSize}
                  error={errors?.adminFeatures?.expirationDate ? true : false}
                  helperText={errors?.adminFeatures?.expirationDate?.message}
                />}
              name="adminFeatures.expirationDate"
              readOnly={readOnly}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Switch 
              control={control}
              label="Continuity after expiration"
              name="adminFeatures.continuityAfterExpiration"
              readOnly={readOnly}
              // defaultValue={false}
            />
            {/* <FormControlLabel
              label="Continuity after expiration"
              labelPlacement="start"
              control={
                <Controller
                  name="adminFeatures.continuityAfterExpiration"
                  control={control}
                  defaultValue={false}
                  as={Switch}
                  disabled={readOnly}
                />
              }
            /> */}
          </Grid>

          <Grid item xs={12} sm={4}>
            <Switch 
              control={control}
              label="Agreement Require Visitation"
              name="adminFeatures.agreementRequireVisitation"
              readOnly={readOnly}
              // defaultValue={false}
            />
            {/* <FormControlLabel
              label="Agreement Require Visitation"
              labelPlacement="start"
              control={
                <Controller
                  name="adminFeatures.agreementRequireVisitation"
                  control={control}
                  defaultValue={false}
                  as={Switch}
                  disabled={readOnly}
                />
              }
            /> */}
          </Grid>

         

        </Grid>
        {/* <pre>{JSON.stringify(formState, null, 2)}</pre> */}
      </form>
      {/* { formState.isSubmitting && (<LinearProgress />)} */}
    </IconCard>
  )
}

export default AdminFeatures;