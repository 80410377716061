import React from 'react';

import { useFieldArray, FormProviderProps, Controller,  } from "react-hook-form";

import {
  Typography,
  TextField,
  MenuItem,
  Grid,
  IconButton
} from '@material-ui/core';

import Button from 'components/Button';
import { makeStyles } from "@material-ui/core/styles";
import styles from './styles';

import {Add, DeleteForever} from '@material-ui/icons';

interface IComplement {
  index: number;
  control: FormProviderProps["control"];
  register: FormProviderProps["register"];
  readOnly?: boolean;
  loading?: boolean;
}
const useStyles = makeStyles(styles);

const Complement: React.FC<IComplement> = (props) => {
  const classes = useStyles();
  const { index, control, register, readOnly, loading} = props;

  const { fields, prepend, remove} = useFieldArray({
    control,
    name: `communication[${index}].fields`
  });

  return (
    <Grid container spacing={2} style={{paddingTop: '10px'}}>
      
      <Grid item>
        <Typography style={{padding: '14px'}} variant="body1"><b>Complement:</b></Typography>
      </Grid>
      <Grid item>
        {!readOnly &&
          <Button 
            color="rose" 
            justIcon
            round
            aria-label="add"
            onClick={() => prepend({name: "", value: ""})}
            disabled={loading}
            // className={classes.margin}
          >
            <Add />
          </Button>
        }
      
      </Grid>
          <Grid container item xs={12}>
          {fields.map( (item, nestedIndex) => (
              <Grid container item key={item.id} spacing={1}>
                <Grid item sm={4}>
                <Controller 
                  select
                  label="Complement Type"
                  control={control}
                  variant="outlined"
                  InputProps={{ readOnly }}
                  name={`communication[${index}].fields[${nestedIndex}].name`}
                  fullWidth
                  size="small"
                  defaultValue={item.name}
                  className={classes.bgPaper}
                  as={
                    <TextField>
                      <MenuItem value="">{" "}</MenuItem>
                      <MenuItem  value='Extension'>Extension</MenuItem>
                      <MenuItem  value='Notes'>Notes</MenuItem>
                    </TextField>
                  }
                />
                </Grid>

                <Grid item sm={7}>
                  <TextField 
                    variant="outlined"
                    fullWidth
                    label={`Complement Value`}
                    size="small"
                    inputRef={register()}
                    name={`communication[${index}].fields[${nestedIndex}].value`}
                    InputProps={{ readOnly }}
                    defaultValue={item.value}
                    className={classes.bgPaper}
                  />
                </Grid>

              <Grid item sm={1}>
                {!readOnly && 
                  <IconButton 
                    onClick={() => {remove(nestedIndex)}}
                    style={{color: '#f44336'}}
                  >
                    <DeleteForever/>
                  </IconButton>
                }
                
              </Grid>
              </Grid>
            ))}
            </Grid>

    </Grid>
  )
}

export default Complement;