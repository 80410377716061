import React, {useState, useEffect} from 'react';

import {
  useHistory,
} from 'react-router-dom';

// import Table from 'components/Table';
import Button from 'components/Button';
import Api from 'Services/api';
import { FaRegBuilding } from 'react-icons/fa';
import { Sync, CloudDownload, Tab,  RemoveRedEyeOutlined, Edit, OpenInNew, DeleteForever} from '@material-ui/icons';
import SuperTable from 'components/SuperTable';
import IconCard from 'components/IconCard';

import {Link} from 'react-router-dom';

import {Grid, IconButton} from '@material-ui/core';


const Account: React.FC = () => {
  const history = useHistory();
  const [accounts, setAccounts] = useState([]);

  const [loading, setLoading] = useState(false);
  const [isFrozen, setIsFrozen] = useState(false);

  async function getAccounts (){
    setLoading(true);
    const response = await Api.get('/accounts');
    setAccounts(response.data);
    setLoading(false);
  }

  async function createAccount(){
    setLoading(true);
    const response = await Api.post('/account');
    const id = response?.data?.id;
    history.push(`/account/edit/${id}`);
  }

  async function deleteAccount (id: string) {
    setLoading(true);
    const response = await Api.delete(`/account/${id}`);
    console.log(response);
    getAccounts();
  }

  async function syncAccounts () {
    setLoading(true);
    await Api.get('/sync/accounts');
    await getAccounts();
    setLoading(false);
  }

  async function downloadAccountsCSV (){
    setLoading(true);
    try {
      Api({
        url: '/accounts/export/planswift-csv', //your url
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
         const url = window.URL.createObjectURL(new Blob([response.data]));
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute('download', 'accounts.csv'); //or any other extension
         document.body.appendChild(link);
         link.click();
         setLoading(false);
      });
    }
    catch(error){
      setLoading(false);
    }
  }

  useEffect(() => {
    getAccounts();    
  }, []);

  // const columnsx = [
  //   {key: 'name', title: 'Name', dataKey: 'identification.accountName', resizable: true, width: 300, frozen: 'left'},
  //   {key: 'type', title: 'Type', dataKey: 'identification.type', resizable: true, width: 150},
  //   {key: 'size', title: 'Size', dataKey: 'identification.size', resizable: true, width: 150},
  //   {key: 'industry', title: 'Industry', dataKey: 'identification.industry', resizable: true, width: 150},
  //   {key: 'actions', title: 'Actions', dataKey: '', resizable: false, width: 150, frozen: 'right'},
  // ];

  const [columns, setColumns] = useState<any[]>([
    {key: 'name', title: 'Name', dataKey: 'identification.accountName', resizable: true, width: 300},
    {key: 'type', title: 'Type', dataKey: 'identification.type', resizable: true, width: 150},
    {key: 'size', title: 'Size', dataKey: 'identification.size', resizable: true, width: 160},
    {key: 'industry', title: 'Industry', dataKey: 'identification.industry', resizable: true, width: 180},
    {key: 'actions', title: 'Actions', dataKey: '', resizable: false, width: 200, 
    cellRenderer: (data: any) => {
      // console.log(data);
      return (
        <>
          <IconButton
            component={Link}
            to={`/account/edit/${data.rowData.id}`}
            target="_blank"
          >
              <OpenInNew style={{color: '#ff9800'}} />
          </IconButton>

          <IconButton
            component={Link}
            to={`/account/view/${data.rowData.id}`}
          >
              <RemoveRedEyeOutlined style={{color: '#00acc1'}} />
          </IconButton>

          <IconButton
            component={Link}
            to={`/account/edit/${data.rowData.id}`}
          >
              <Edit style={{color: '#4caf50'}} />
          </IconButton>

          <IconButton
            onClick={() => {
              if(data.rowData?.id){
                deleteAccount(data.rowData?.id);
              }
            }}
          >
            <DeleteForever color="error" />
          </IconButton>
  
        </>
      )
    }},
  ]);

  const tableHeight = loading ? 204 : 200;

  const frozen = () => {
    if(!isFrozen){
      const newColumns = columns.map(col => {
        if(col.key === 'name'){
          return {...col, frozen: 'left'};
        }
        else{
          return col;
        }
      });
      setColumns(newColumns);
      setIsFrozen(true);
    }
    else{
      const newColumns = columns.map(col => {
        if(col.key === 'name'){
          let newCol = {...col};
          if(newCol.frozen){
            delete newCol.frozen;
          }
          
          return newCol;
        }
        else{
          return col;
        }
      });
      setColumns(newColumns);
      setIsFrozen(false);
    }
    
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <IconCard 
            icon={<FaRegBuilding />}
            iconColor="rose"
            title="Account"
            margin={false}
            loading={loading}
            addFunction={createAccount}
            buttons={
              <>
                <Button 
                  color="primary" 
                  justIcon
                  round
                  aria-label="add"
                  onClick={() => frozen()}
                  disabled={loading}
                  // className={classes.margin}
                >
                  <Tab />
                </Button>

                <Button 
                  color="success" 
                  justIcon
                  round
                  aria-label="add"
                  onClick={() => downloadAccountsCSV()}
                  disabled={loading}
                  // className={classes.margin}
                >
                  <CloudDownload />
                </Button>
                
                <Button 
                  color="warning" 
                  justIcon
                  round
                  aria-label="add"
                  onClick={() => syncAccounts()}
                  disabled={loading}
                  // className={classes.margin}
                >
                  <Sync />
                </Button>
                
              </>
            }
          >
            <div 
              style={{ 
                maxWidth: '100%', 
                height: `calc(100vh - ${tableHeight}px)`, 
                minHeight: '250px'}}
            >
              <SuperTable 
                columns={columns}
                data={accounts}
              />
            </div>
          </IconCard>
        </Grid>
      </Grid>
    </>
  )
}

export default Account;