import React, {useState, useEffect} from 'react';
// import Button from "components/Button";
import Grid from '@material-ui/core/Grid';
// import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Api from 'Services/api';
import { Accounts } from '@dsrcloud/types';
import {
  TextField,
  // Switch,
  // FormControlLabel,
  // MenuItem,
} from '@material-ui/core';


import IconCard from 'components/IconCard';

import { useForm, Controller } from "react-hook-form";

import {FaDollarSign} from 'react-icons/fa';
// import { FaAngrycreative } from 'react-icons/fa';

// import { makeStyles } from "@material-ui/core/styles";
// import styles from './styles';
// const useStyles = makeStyles(styles);

interface ICompanyReceivingService {
  readOnly?: boolean,
  defaultValues?: any,
  onSubmit?: Function
}


const CompanyReceivingService: React.FC<ICompanyReceivingService> = (props) => {
  // const classes = useStyles();

  const {
    defaultValues,
    // readOnly,
    onSubmit,
    // ...rest
  } = props;


  const [ accounts, setAccounts ] = useState<Accounts>([]);

  useEffect(() => {
    async function fetchAccounts(){
      const response = await Api.get('/accounts');
      setAccounts(response.data);
    }
    fetchAccounts();
  }, []);

  const { 
    // register, 
    handleSubmit, 
    // errors, 
    formState, 
    reset, 
    control } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const [ loading, setLoading] = useState(false);
  
  const submit = async (data: any) => {
    if (onSubmit) {
      setLoading(true);
      // alert(JSON.stringify(data));
      // alert(JSON.stringify({companyReceivingService: data.companyReceivingService.id}));
      // setLoading(false);
      try {
        const response = await onSubmit({companyReceivingService: data.companyReceivingService.id});
        // alert(JSON.stringify({companyReceivingService: response.companyReceivingService}));
        reset({companyReceivingService: response.companyReceivingService});
      }
      catch(error){
        alert('error');
      }finally{
        setLoading(false);
      }
    }
  }

  const fieldSize = 'small';
  return (
    <IconCard 
      icon={<FaDollarSign />}
      iconColor="warning"
      title="Company Receiving Service"
      loading={loading}
      isDirty={formState.isDirty}
      // isDirty={true}
      resetFunction={reset}
      saveFunction={ () => handleSubmit(submit)() }
    >
      <form onSubmit={handleSubmit(submit)}>
        <Grid container spacing={2}>

          <Grid item xs={12} sm={12}>

          <Controller 
            render={props => (
              <Autocomplete 
                {...props}
                options={accounts}
                getOptionLabel={ option => {
                  let optionText = '';
                  if (option?.identification?.accountName){
                    optionText = option.identification.accountName;
                  }
                  return optionText;
                }}
                renderOption={(option) => (
                  <span>{option.identification?.accountName}</span>
                )}
                getOptionSelected={ (option, value) => option?.id === value?.id}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Choose an Account"
                    variant="outlined"
                    size={fieldSize}
                  />
                )}
                onChange={(_, data) => props.onChange(data)}
              />
            )}
            name="companyReceivingService"
            control={control}
          />


          </Grid>




        </Grid>

      </form>

    </IconCard>
  )
}

export default CompanyReceivingService;