import React, {useState, useContext} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';

import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { NavLink, useParams } from "react-router-dom";
import Collapse from '@material-ui/core/Collapse';
import styles from './styles';

import {FaUserAlt} from 'react-icons/fa';
import {MdKeyboardArrowUp, MdKeyboardArrowDown} from 'react-icons/md';
import {FcBusinessman} from 'react-icons/fc';
import {FiLogOut} from 'react-icons/fi';

import {MainContext} from 'context/MainContext';

const useStyles = makeStyles(styles);

interface route {
  path: string,
  name: string,
  icon: React.ReactNode,
}

interface ISidenav {
  color: "purple" | "blue" | "green" | "orange" | "red",
  open?: boolean,
  bgImage?: string,
  logo?: string,
  routes: route[],
  handleDrawerToggle?(): any,
}

const CustomDrawer: React.FC<ISidenav> = (props) => {
  const classes = useStyles();
  const {user, LogOut} = useContext(MainContext);
  // useParams to force rendering
  useParams();

  const {
    color,
    open,
    bgImage,
    logo,
    routes,
    // handleDrawerToggle,
    // ...rest
  } = props;



  var brand = (
    <div className={classes.logo}>
      <div
        className={classes.logoLink}
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
      </div>
    </div>
  );

  const handleDrawerToggle = props.handleDrawerToggle ? props.handleDrawerToggle : () => {};

  // function activeRoute(routeName: string) {
  //   // return window.location.href.indexOf(routeName) > -1 ? true : false;
  //   return window.location.pathname === routeName;
  // }

  const links = (
    <List>
      {routes.map((prop, key) => {
        return (
          <div className={classes.item} key={key}>
            <ListItem 
              button
              onClick={() => {handleDrawerToggle()}}
              className={classes.itemLink}
              component={NavLink}
              to={prop.path}
              activeClassName={classes[color]}
            >
              {prop.icon ? (
                <div className={clsx(classes.itemIcon)}>
                  {prop.icon}
                </div>
              ) : null}
              <ListItemText
                primary={prop.name}
                className={clsx(classes.itemText, classes.whiteFont)}
                disableTypography={true}
              />
            </ListItem>
          </div>
        )
      })}
    </List>
  )

  // var links = (
  //   <List className={classes.list}>
  //     {routes.map((prop, key) => {
  //       var listItemClasses = clsx({
  //           [" " + classes[color]]: activeRoute(prop.path)
  //         });
  //       var whiteFontClasses = clsx({
  //         [" " + classes.whiteFont]: activeRoute(prop.path)
  //       });
  //       return (
  //         <NavLink
  //           to={prop.path}
  //           className={classes.item}
  //           // activeClassName="active"
  //           key={key}
  //         >
  //           <ListItem button className={classes.itemLink + listItemClasses} onClick={() => {handleDrawerToggle()}}>


  //             {prop.icon ? (
  //               <div className={clsx(classes.itemIcon, whiteFontClasses)}>
  //                 {prop.icon}
  //               </div>
  //             ) : null}


  //             <ListItemText
  //               primary={prop.name}
  //               className={clsx(classes.itemText, whiteFontClasses)}
  //               disableTypography={true}
  //             />
  //           </ListItem>
  //         </NavLink>
  //       );
  //     })}
  //   </List>
  // );


  const [userOpen, setUserOpen] = useState(false);

  const userCollapse = (
    <div className={classes.user}>
      <List className={classes.userList}>
        <ListItem 
          button 
          className={classes.itemLink} 
          onClick={() => {
            setUserOpen(!userOpen)
          }}
        >
          <div className={clsx(classes.itemIcon)}>
            <FaUserAlt size="30"/>
          </div>
          <ListItemText
            primary={`${user.firstName} ${user.lastName}`}
            className={clsx(classes.itemText)}
            disableTypography={true}
          />
          {userOpen ? <MdKeyboardArrowUp size="20"/> : <MdKeyboardArrowDown size="20"/>}
        </ListItem>

        <Collapse in={userOpen}>
          <NavLink
            to="/profile"
            className={classes.item}
            // activeClassName="active"
            // className={classes.item +" " + classes.itemLink}
            activeClassName={classes.blue}
            
          >
            <ListItem 
              button 
              className={classes.itemLink} 
              onClick={() => {handleDrawerToggle()}}
            >
                <div className={clsx(classes.itemIcon)}>
                  <FcBusinessman size="25"/>
                </div>
              <ListItemText
                primary="My Profile"
                className={clsx(classes.itemText)}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>

          <NavLink
            to="/login"
            className={classes.item}
            // activeClassName="active"
            // className={classes.item +" " + classes.itemLink}
            activeClassName={classes.blue}
            onClick={LogOut}
          >
            <ListItem 
              button 
              className={classes.itemLink} 
              onClick={() => {handleDrawerToggle()}}
            >
                <div className={clsx(classes.itemIcon)}>
                  <FiLogOut size="25"/>
                </div>
              <ListItemText
                primary="Logout"
                className={clsx(classes.itemText)}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        </Collapse>
      </List>
    </div>
  );


  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={open}
          classes={{
            paper: clsx(classes.drawerPaper),
          }}
          ModalProps={{
            keepMounted: true
          }}
          onClose={handleDrawerToggle}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {userCollapse}
            {links}
          </div>
          { bgImage !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + bgImage + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>

      <Hidden smDown implementation="css">
        <Drawer
          anchor={'right'}
          variant="permanent"
          open
          classes={{
            paper: clsx(classes.drawerPaper)
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {userCollapse}
            {links}
          </div>
          { bgImage !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + bgImage + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>

    </div>
  )
}

export default CustomDrawer;