import React, {useContext, useState} from 'react';
import Button from 'components/Button';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import styles from './styles';
import Typography from '@material-ui/core/Typography';
import { useForm } from "react-hook-form";
import { TextField } from '@material-ui/core';
import Swal from 'components/Swal2';
import { useHistory, Link } from "react-router-dom";
import Header from 'components/Header';
import {MainContext} from 'context/MainContext';
import Api from 'Services/api';



import {
  LinearProgress,
  InputAdornment,
  IconButton
} from '@material-ui/core';

import { Visibility, VisibilityOff} from '@material-ui/icons';

const useStyles = makeStyles(styles);

const Login: React.FC = () => {
  const classes = useStyles();
  const {setUser} = useContext(MainContext);

  const { handleSubmit, register, errors } = useForm();
  let history = useHistory();
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const response = await Api.post('/login', data);
      localStorage.setItem('user', JSON.stringify(response?.data));
      setUser(response?.data);
      Api.defaults.headers = {
        Authorization: `Bearer ${response?.data?.token}`
      }
      history.push('/dashboard');
    }
    catch(error){
      setLoading(false);
      Swal.fire({
        title: 'Error!',
        text: JSON.stringify(error?.response?.data?.error),
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonProps: {color: "info", round: true}
      })
    }
  };

  

  return (
    <>
    
    <div
      className={classes.pageHeader}
      style={{
        backgroundImage: "url(/assets/img/bg20.jpg)",
        // backgroundImage: "url(https://source.unsplash.com/random?cosmos)",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    >

  <div className={classes.animatedBackground}>
  <div className={classes.container}>
    <Header 
      color="transparent"
      absolute
      brand={<img 
        src="assets/img/logo.png" 
        alt="logo" 
        height="45px"
        style={{
          // filter: 'drop-shadow(5px 5px 5px #1fc1d5)'
        }}
      />}
      rightLinks={
        <Button 
          fullWidth 
          round
          color="info"
          component={Link}
          to="/register"
          // onClick={() => {
          //   history.push('/register');
          // }}
        >
          Register
        </Button>
      }
      // fixed
    />
    <Grid
      container
      justify="center"
    >
      <Grid item xs={12} sm={12} md={4}>
        <Card>
          <CardHeader color="info"  className={classes.cardHeader}>
            <Typography variant="h5" style={{margin: "8px"}}>Login</Typography>
          </CardHeader>
          <CardBody>
            <form 
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >

              <div>

              <TextField 
                fullWidth
                autoFocus
                variant="outlined"
                label="Username or Email"
                name="username" 
                inputRef={register}
                style={{marginTop:"12px"}}
                error={errors.email ? true : false}
                inputProps={{
                  autoCapitalize: 'none',
                }}
              />

              
              <TextField 
                fullWidth
                variant="outlined"
                label="Password"
                name="password" 
                inputRef={register}
                style={{marginTop:"15px"}}
                type={showPassword ? 'text' : 'password'} 
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={ e => e.preventDefault()}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}

              />


              </div>
              <div style={{marginTop:"15px"}}>
                
              <Button 
                type="submit"
                color="info"
                fullWidth
                round
                disabled={loading}
              >
                Login
              </Button>
              
              </div>
            </form>
            {loading && 
              <>
                <LinearProgress style={{marginTop: '10px'}}/>
              </>
            }
          </CardBody>
        </Card>
      </Grid>
    </Grid>
    
    </div>
    
  </div>
  </div>
  
  </>
  )
}

export default Login;
