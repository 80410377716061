import React, {useState, useEffect} from 'react';

// import  { useParams } from 'react-router-dom';

// import {
//   useHistory,
// } from 'react-router-dom';

// import Table from 'components/Table';
import Api from 'Services/api';
import { FaRegBuilding } from 'react-icons/fa';

import {calcNext} from '@dsrcloud/utils';

import {format} from 'date-fns';

import { Tab,  RemoveRedEyeOutlined, Edit, OpenInNew} from '@material-ui/icons';
import SuperTable from 'components/SuperTable';
import IconCard from 'components/IconCard';

import {Link} from 'react-router-dom';

import {Grid, IconButton} from '@material-ui/core';

import Button from 'components/Button';

interface AgreementServiceTableProps{
  readOnly?: boolean;
  defaultValues?: any;
}


const AgreementServiceTable: React.FC<AgreementServiceTableProps> = () => {
  // const history = useHistory();
  const [services, setServices] = useState([]);

  const [loading] = useState(false);
  const [isFrozen, setIsFrozen] = useState(false);

  // const { agreementId } = useParams();

  // async function createAccount(){
  //   const response = await Api.post('/agreement/');
  //   const id = response?.data?.id;
  //   history.push(`/account/edit/${id}`);
  // }

  // async function deleteAccount (id: string) {
  //   const response = await Api.delete(`/account/${id}`);
  //   console.log(response);
  //   getAccounts();
  // }

  // async function getAgreementServices (){
  //   const response = await Api.get(`/agreement-services`);
  //   setServices(response.data);
  // }

  // async function deleteAgreementService (id: string) {
  //   const response = await Api.delete(`/agreement-service/${id}`);
  //   console.log(response);
  //   getAgreementServices();
  // }

  useEffect(() => {
    async function getAgreementServices (){
      const response = await Api.get(`/agreement-services`);
      // console.log(response);
      setServices(response.data);
    }
    getAgreementServices();    
  }, []);


  const [columns, setColumns] = useState<any[]>([
    {key: 'agreementNumber', title: 'Agreement Number', dataKey: 'identification.agreement.identification.number', resizable: true, width: 180},
    {key: 'serviceName', title: 'Service Name', dataKey: 'identification.serviceName', resizable: true, width: 180},
    {key: 'provider', title: 'Provider', dataKey: 'serviceProviderCompany.identification.accountName', resizable: true, width: 160},
    {key: 'receiving', title: 'Receiving', dataKey: 'companyReceivingService.identification.accountName', resizable: true, width: 180},
    {key: 'propertyName', title: 'Property Name', dataKey: 'address.propertyName', resizable: true, width: 180},
    {key: 'nextService', title: 'Next Service', dataKey: '', resizable: false, width: 200, 
      cellRenderer: (data: any) => {
        let nextService = calcNext(data.rowData?.schedule?.recurrence);
        let nextServiceText = '-';
        if(nextService){
          nextServiceText = format(nextService, 'MM-dd-yy');
        }
        return (
          <>
            {nextServiceText}
          </>
        );
      }
    },
    {key: 'city', title: 'City', dataKey: 'address.city', resizable: true, width: 180},
    {key: 'access', title: 'Access', dataKey: 'technicalFeatures.access', resizable: true, width: 180},
    {key: 'actions', title: 'Actions', dataKey: '', resizable: false, width: 200, 
    cellRenderer: (data: any) => {
      // console.log(data);
      return (
        <>
          <IconButton
            component={Link}
            // to={`/contact/edit/${data.rowData.id}`}
            to={`/agreement/edit/${data.rowData?.identification?.agreement?.id}/service/${data.rowData.id}`}
            target="_blank"
          >
              <OpenInNew style={{color: '#ff9800'}} />
          </IconButton>

          <IconButton
            component={Link}
            // to={`/contact/view/${data.rowData.id}`}
            to={`/agreement/view/${data.rowData?.identification?.agreement?.id}/service/${data.rowData.id}`}
          >
              <RemoveRedEyeOutlined style={{color: '#00acc1'}} />
          </IconButton>

          <IconButton
            component={Link}
            // to={`/contact/edit/${data.rowData.id}`}
            to={`/agreement/edit/${data.rowData?.identification?.agreement?.id}/service/${data.rowData.id}`}
          >
              <Edit style={{color: '#4caf50'}} />
          </IconButton>

          {/* <IconButton
            onClick={() => {
              if(data.rowData?.id){
                // deleteContact(data.rowData?.id);
              }
            }}
          >
            <DeleteForever color="error" />
          </IconButton> */}
  
        </>
      )
    }},
  ]);

  const tableHeight = loading ? 204 : 200;

  const frozen = () => {
    if(!isFrozen){
      const newColumns = columns.map(col => {
        if(col.key === 'agreementNumber' || col.key === 'serviceName'){
          return {...col, frozen: 'left'};
        }
        else{
          return col;
        }
      });
      setColumns(newColumns);
      setIsFrozen(true);
    }
    else{
      const newColumns = columns.map(col => {
        if(col.key === 'agreementNumber' || col.key === 'serviceName'){
          let newCol = {...col};
          if(newCol.frozen){
            delete newCol.frozen;
          }
          
          return newCol;
        }
        else{
          return col;
        }
      });
      setColumns(newColumns);
      setIsFrozen(false);
    }
    
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <IconCard 
            icon={<FaRegBuilding />}
            iconColor="rose"
            title="Agreement Services"
            margin={false}
            loading={loading}
            // addFunction={createContact}
            buttons={
              <>
                <Button 
                  color="primary" 
                  justIcon
                  round
                  aria-label="add"
                  onClick={() => frozen()}
                  disabled={loading}
                  // className={classes.margin}
                >
                  <Tab />
                </Button>
              </>
            }
          >
            <div 
              style={{ 
                maxWidth: '100%', 
                height: `calc(100vh - ${tableHeight}px)`, 
                minHeight: '250px'}}
            >
              <SuperTable 
                columns={columns}
                data={services}
              />
            </div>
          </IconCard>
        </Grid>
      </Grid>
    </>
  )
}

export default AgreementServiceTable;