import React from 'react';
// import Button from "@material-ui/core/Button";
// import { ButtonProps } from '@material-ui/core/Button';
// import { makeStyles } from "@material-ui/core/styles";
// import clsx from 'clsx';
// import styles from './styles';

import NumberFormat from 'react-number-format';

import {
  // FormControlLabel,
  // Switch
  TextField
} from '@material-ui/core';

import {Control, Controller} from 'react-hook-form';

// const useStyles = makeStyles(styles);


interface IMoneyInput {
  name: string;
  control: Control;
  defaultValue?: number;
  label?: string;
  readOnly?: boolean;
}

const CustomInput = (inputProps: any) => (
  <TextField  
    {...inputProps}
    size="small"
  />
)


const MoneyInput: React.FC<IMoneyInput> = (props) => {
  // const classes = useStyles();

  const {
    name, 
    control,
    label,
    readOnly,
    defaultValue,
  } = props;

  return (
    <Controller 
      control={control}
      name={name}
      defaultValue={defaultValue ? defaultValue : 0}
      
      render={ props => (        
        <NumberFormat 
          thousandSeparator
          prefix={'$'}
          decimalScale={2}
          className="input"

          customInput={CustomInput}

          label={label}
          variant="outlined"
          fullWidth
          InputProps={{ readOnly }}

          value={props.value}
          onValueChange={ (value) => {
            props.onChange(value.value);
          }}
        />
      )}
    />
  )
}

export default MoneyInput;