import { createStyles, Theme } from '@material-ui/core/styles';
// import { hexToRgb } from 'utils/themeUtils';

const styles = ( {gradientPalette, palette}: Theme) => createStyles({
  tableHeader: {
    // background: 'transparent',
    background: palette.background.paper,
    // background: 'linear-gradient(60deg, #999, #FFF)',
    // borderRadius: '0px 20px 20px 0px'
    // fontSize: '15px',
  },
});

export default styles;
