import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';

import {
  TextField,
  MenuItem
} from '@material-ui/core';

import IconCard from 'components/IconCard';
import { useForm, Controller } from "react-hook-form";
import {GiGearHammer} from 'react-icons/gi';

interface IIdentificationAgreementService {
  readOnly?: boolean,
  defaultValues?: any,
  onSubmit?: Function
}

const TechnicalFeatures: React.FC<IIdentificationAgreementService> = (props) => {
  // const classes = useStyles();

  const {
    defaultValues,
    readOnly,
    onSubmit,
    // ...rest
  } = props;

  console.log('default values');
  console.log(defaultValues);


  const [ types] = useState({
    category: [
      'A/V & PA',
      'Automation',
      'Cabling Structure',
      'Fire Alarm',
      'ISP',
      'Radio',
      'Security',
      'Telco',
    ],
    // class: [
    //   'Consulting',
    //   'Design',
    //   'Installation',
    //   'Service',
    // ],
    // mode: [
    //   'On Time',
    //   'Agreement',
    // ],
    mode: [
      'Consulting',
      'Design',
      'Installation',
      'Monitoring',
      'Service',
    ],
    access: [
      'On Site',
      'Remotely',
    ]
  });

  const { register, handleSubmit, formState, reset, control } = useForm({
    mode: 'onChange',
    defaultValues,
  });


  const [loading, setLoading] = useState(false);


  const submit = async (data: any) => {
    setLoading(true);
    if (onSubmit) {
      try {
        const response = await onSubmit(data);
        reset(response);
      }
      catch(error){
        alert('error');
      }
      finally{
        setLoading(false);
      }
    }
  }

  const fieldSize = 'small';
  return (
    <IconCard 
      icon={<GiGearHammer />}
      iconColor="rose"
      title="Technical Features"
      readOnly={readOnly}
      isDirty={formState.isDirty}
      resetFunction={reset}
      saveFunction={ () => handleSubmit(submit)() }
      loading={loading}
    >
      <form onSubmit={handleSubmit(submit)}>
        <Grid container spacing={2}>

          <Grid item xs={12} sm={4}>
            <Controller 
                select
                label="Category"
                control={control}
                variant="outlined"
                InputProps={{ readOnly }}
                name="technicalFeatures.category"
                // error={errors?.billingFeatures?.terms ? true : false}
                // helperText={errors?.billingFeatures?.terms?.message}
                fullWidth
                size={fieldSize}
                // defaultValue=""
                as={
                  <TextField>
                    <MenuItem value=""></MenuItem>
                    {types.category?.map( item => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                    ))}
                  </TextField>
                }
            />
          </Grid>

          {/* <Grid item xs={12} sm={3}>
            <Controller 
                select
                label="Class"
                control={control}
                variant="outlined"
                InputProps={{ readOnly }}
                name="technicalFeatures.class"
                fullWidth
                size={fieldSize}
                as={
                  <TextField>
                    <MenuItem value=""></MenuItem>
                    {types.class?.map( item => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                    ))}
                  </TextField>
                }
            />
          </Grid> */}

          <Grid item xs={12} sm={4}>
            <Controller 
                select
                label="Mode"
                control={control}
                variant="outlined"
                InputProps={{ readOnly }}
                name="technicalFeatures.mode"
                // error={errors?.billingFeatures?.terms ? true : false}
                // helperText={errors?.billingFeatures?.terms?.message}
                fullWidth
                size={fieldSize}
                // defaultValue=""
                as={
                  <TextField>
                    <MenuItem value=""></MenuItem>
                    {types.mode?.map( item => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                    ))}
                  </TextField>
                }
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Controller 
                select
                label="Access"
                control={control}
                variant="outlined"
                InputProps={{ readOnly }}
                name="technicalFeatures.access"
                // error={errors?.billingFeatures?.terms ? true : false}
                // helperText={errors?.billingFeatures?.terms?.message}
                fullWidth
                size={fieldSize}
                // defaultValue=""
                as={
                  <TextField>
                    <MenuItem value=""></MenuItem>
                    {types.access?.map( item => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                    ))}
                  </TextField>
                }
            />
          </Grid>

          <Grid item xs={12} sm={12} >
              <TextField 
                variant="outlined"
                fullWidth
                label={`Details`}
                size={fieldSize}
                inputRef={register()}
                name={`technicalFeatures.details`}
                InputProps={{ readOnly }}
                // defaultValue={item.propertyName}
                // defaultValue=""
                multiline
              />
            </Grid>


        </Grid>
      </form>
    </IconCard>
  )
}

export default TechnicalFeatures;