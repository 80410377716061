import React from 'react';
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import styles from './styles';

const useStyles = makeStyles(styles);

interface ICardIcon {
  className?: string,
  plain?: boolean,
  profile?: boolean,
  stats?: boolean,
  chart?: boolean,
}

const CustomCard: React.FC<ICardIcon> = (props) => {
  const classes = useStyles();

  const {
    className, 
    plain,
    profile,
    stats,
    chart,
    children,
    ...rest
  } = props;

  let cardClasses = clsx({
    [classes.cardFooter]: true,
    [classes.cardFooterPlain]: plain,
    [classes.cardFooterProfile]: profile,
    [classes.cardFooterStats]: stats,
    [classes.cardFooterChart]: chart
  });

  if (className) {
    cardClasses += ' ' + clsx(className, true);
  }

  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  )
}

export default CustomCard;