import React from 'react';

import styled from 'styled-components';

// import {} from 'styled-components/cssprop';

import  {Theme, useTheme} from '@material-ui/core';

import BaseTable, { AutoResizer } from 'react-base-table';
// import 'react-perfect-scrollbar/dist/css/styles.css';
// import PerfectScrollbar from 'react-perfect-scrollbar';

// const generateColumns = (count = 10, prefix = 'column-', props: any) =>
//   new Array(count).fill(0).map((column, columnIndex) => ({
//     ...props,
//     key: `${prefix}${columnIndex}`,
//     dataKey: `${prefix}${columnIndex}`,
//     title: `Column ${columnIndex}`,
//     width: 150,
//     resizable: true,
//     // sortable: true,
//     // frozen: columnIndex === 0 ? Column.FrozenDirection.LEFT : null,
//   })
// );

// const generateData = (columns: any, count = 200, prefix = 'row-') =>
//   new Array(count).fill(0).map((row, rowIndex) => {
//     return columns.reduce(
//       (rowData: any, column: any, columnIndex: any) => {
//         rowData[column.dataKey] = `Row ${rowIndex} - Col ${columnIndex}`
//         return rowData
//       },
//       {
//         id: `${prefix}${rowIndex}`,
//         parentId: null,
//       }
//     )
//   }
// );

// let columns = generateColumns(10, 'coluna-', '');
// columns[0].frozen = Column.FrozenDirection.LEFT;
// const data = generateData(columns, 200);
// console.log(data);
// console.log(columns);

// const dados = [
//   {id: '131231', 'coluna-0': 'DSR TECHNOLOGIES', 'coluna-1': 20, 'coluna-2': 'Masculino', coco: {c1: 'heheh foi'}},
// ];

// const colunas = [
//   {key: 'coluna-0', dataKey: 'coluna-0', frozen: 'left', resizable: true, title: 'Name', width: 300},
//   {key: 'coluna-1', dataKey: 'coluna-1', frozen: 'left', resizable: true, title: 'idade', width: 80},
//   {key: 'coluna-2', dataKey: 'coluna-2', frozen: 'left', resizable: true, title: 'sexo', width: 100},
//   {key: 'coco', dataKey: 'coco.c1', frozen: 'left', resizable: true, title: 'sexo', width: 100},
// ];


const MyComponent: React.FC<{ theme: Theme }> = styled(({ theme,columns, data,...props }) => {
  
  return (
    <AutoResizer>
      {({ width, height }) => (
        // <Box {...props}>
          <BaseTable
            fixed
            data={data} 
            // data={dados}
            width={width} 
            height={height} 
            // columns={colunas} 
            columns={columns}
            {...props}
            // estimatedRowHeight={53}
          />
        // </Box>
      )}
    </AutoResizer>
  )
})`
    // padding-top: ${props => props.theme.spacing(2)}px;

    .BaseTable {
      // box-shadow: 0 2px 4px 0 #eeeeee;
      // background-color: #ffffff;
      position: relative;
      box-sizing: border-box;
      font-size: 13px;
    }
    
    .BaseTable--disabled {
      opacity: 0.7;
      pointer-events: none;
    }
    
    .BaseTable--dynamic .BaseTable__row {
      overflow: hidden;
      align-items: stretch;
    }
    
    .BaseTable:not(.BaseTable--dynamic) .BaseTable__row-cell-text,
    .BaseTable .BaseTable__row--frozen .BaseTable__row-cell-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    
    .BaseTable__table {
      // background-color: #ffffff;
      position: absolute;
      top: 0;
      display: flex;
      flex-direction: column-reverse;
    }
    
    .BaseTable__table-main {
      // outline: 1px solid #eeeeee;
      left: 0;
    }
    
    .BaseTable__table-main .BaseTable__header-cell:first-child, .BaseTable__table-main .BaseTable__row-cell:first-child {
      padding-left: 15px;
    }
    
    .BaseTable__table-main .BaseTable__header-cell:last-child, .BaseTable__table-main .BaseTable__row-cell:last-child {
      padding-right: 15px;
    }
    
    .BaseTable__table-main .BaseTable__header {
      background-color: #f8f8f8;
    }
    
    .BaseTable__table-frozen-left .BaseTable__header,
    .BaseTable__table-frozen-left .BaseTable__body, .BaseTable__table-frozen-right .BaseTable__header,
    .BaseTable__table-frozen-right .BaseTable__body {
      overflow: hidden !important;
    }
    
    .BaseTable__table-frozen-left {
      // box-shadow: 2px 0 4px 0 #eeeeee;
      // TODO edit this line to use shadows
      box-shadow: 2px 0 4px 0 ${props => props.theme.palette.background.default};
      // box-shadow: ${props => props.theme.shadows[2]};
      top: 0;
      left: 0;
    }
    
    .BaseTable__table-frozen-left .BaseTable__header-cell:first-child, .BaseTable__table-frozen-left .BaseTable__row-cell:first-child {
      padding-left: 15px;
    }
    
    .BaseTable__table-frozen-left .BaseTable__header-row,
    .BaseTable__table-frozen-left .BaseTable__row {
      padding-right: 0 !important;
    }
    
    .BaseTable__table-frozen-left .BaseTable__body {
      overflow-y: auto !important;
    }
    
    .BaseTable__table-frozen-right {
      // box-shadow: -2px 0 4px 0 #eeeeee;
      // TODO edit this line to use shadows
      box-shadow: -2px 0 4px 0 ${props => props.theme.palette.background.default};
      // box-shadow: ${props => props.theme.shadows[2]};
      top: 0;
      right: 0;
    }
    
    .BaseTable__table-frozen-right .BaseTable__header-cell:last-child, .BaseTable__table-frozen-right .BaseTable__row-cell:last-child {
      padding-right: 15px;
    }
    
    .BaseTable__table-frozen-right .BaseTable__header-row,
    .BaseTable__table-frozen-right .BaseTable__row {
      padding-left: 0 !important;
    }
    
    .BaseTable__table-frozen-right .BaseTable__body {
      overflow-y: auto !important;
    }
    
    .BaseTable__header {
      overflow: hidden !important;
    }
    
    .BaseTable .BaseTable__header,
    .BaseTable .BaseTable__body {
      outline: none;
    }
    
    .BaseTable__header-row, .BaseTable__row {
      display: flex;
      align-items: center;
      // border-bottom: 1px solid #eeeeee;
      border-bottom: 1px solid ${props => props.theme.palette.divider};
      box-sizing: border-box;
    }
    
    .BaseTable__header-row {
      // background-color: #f8f8f8;
      background-color: ${props => props.theme.palette.background.default};
      font-weight: 700;
    }
    
    .BaseTable__row {
      // background-color: #ffffff;
      background-color: ${props => props.theme.palette.background.paper};
    }
    
    .BaseTable__row:hover, .BaseTable__row--hovered {
      // background-color: #f3f3f3;
      background-color: ${props => props.theme.palette.action.hover};
    }
    
    .BaseTable__row-expanded {
      // border-bottom: 1px solid #eeeeee;
    }
    
    .BaseTable__header-cell, .BaseTable__row-cell {
      min-width: 0;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 7.5px;
      box-sizing: border-box;
    }
    
    .BaseTable__header-cell--align-center, .BaseTable__row-cell--align-center {
      justify-content: center;
      text-align: center;
    }
    
    .BaseTable__header-cell--align-right, .BaseTable__row-cell--align-right {
      justify-content: flex-end;
      text-align: right;
    }
    
    .BaseTable__header-cell {
      position: relative;
      cursor: default;
    }
    
    .BaseTable__header-cell:hover .BaseTable__column-resizer {
      visibility: visible;
      opacity: 0.5;
    }
    
    .BaseTable__header-cell:hover .BaseTable__column-resizer:hover {
      opacity: 1;
    }
    
    .BaseTable__header-cell .BaseTable__sort-indicator {
      display: none;
    }
    
    .BaseTable__header-cell--sortable:hover {
      background-color: #f3f3f3;
      cursor: pointer;
    }
    
    .BaseTable__header-cell--sortable:not(.BaseTable__header-cell--sorting):hover .BaseTable__sort-indicator {
      display: block;
      color: #888888;
    }
    
    .BaseTable__header-cell--sorting .BaseTable__sort-indicator, .BaseTable__header-cell--sorting:hover .BaseTable__sort-indicator {
      display: block;
    }
    
    .BaseTable__header-cell--resizing .BaseTable__column-resizer {
      visibility: visible;
      opacity: 1;
    }
    
    .BaseTable__header-cell--resizing .BaseTable__column-resizer::after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      overflow: hidden;
      content: '';
      left: -9999px;
    }
    
    .BaseTable__header-cell-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
    
    .BaseTable__header-row--resizing .BaseTable__header-cell {
      background-color: transparent;
      cursor: col-resize;
    }
    
    .BaseTable__header-row--resizing .BaseTable__header-cell:not(.BaseTable__header-cell--sorting) .BaseTable__sort-indicator {
      display: none;
    }
    
    .BaseTable__header-row--resizing .BaseTable__header-cell:not(.BaseTable__header-cell--resizing) .BaseTable__column-resizer {
      visibility: hidden;
    }
    
    .BaseTable__column-resizer {
      width: 3px;
      visibility: hidden;
      background-color: #cccccc;
    }
    
    .BaseTable__column-resizer:hover {
      visibility: visible;
      opacity: 1;
    }
    
    .BaseTable__footer {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      // background-color: #ffffff;
    }
    
    .BaseTable__resizing-line {
      cursor: col-resize;
      position: absolute;
      top: 0;
      background-color: #cccccc;
      // background-color: ${props => props.theme.palette.grey[200]};
      width: 3px;
      transform: translateX(-100%);
    }
    
    .BaseTable__empty-layer {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      overflow: hidden;
      // background-color: #ffffff;
    }
    
    // .BaseTable__overlay {
    //   position: absolute;
    //   left: 0;
    //   right: 0;
    //   top: 0;
    //   bottom: 0;
    //   overflow: hidden;
    //   pointer-events: none;
    // }
    
    .BaseTable__overlay > * {
      pointer-events: auto;
    }
    

`


// interface ColumnProps {
//   key: string;

// }

// interface DataProps {
//   rowKey?: string;
// }

interface TableProps {
  columns?: any[];
  data?: any[];
}

export const Wrapper: React.FC<TableProps> = ({children, ...props }) => {
  const theme: Theme = useTheme()
  return (
    <MyComponent theme={theme} {...props}>
      {children}
    </MyComponent>
  )
}

export default Wrapper;