import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';

import  { useParams } from 'react-router-dom';

import Api from 'Services/api';
// import { useForm } from "react-hook-form";
import LinearProgress from '@material-ui/core/LinearProgress';

import IdentificationBlock from './blocks/Identification';
import CommunicationBLock from '../../../sharedBlocks/Communication';
import AddressBlock from '../../../sharedBlocks/Addresses';
// import LogsBlock from '../../../sharedBlocks/Logs';
// const useStyles = makeStyles(styles);

interface AccountParams {
  accountId: string;
  action: string;
}

const Account: React.FC = () => {
  // const classes = useStyles();

  const { accountId, action } = useParams<AccountParams>();

  const [ account, setAccounts ] = useState<any>(undefined); 
  const [ isLoading, setIsLoading ] = useState(true);


  useEffect(() => {
    async function getAccounts(){
      try {
        const response = await Api.get(`/account/${accountId}`);
        console.log(response.data);
        setAccounts(response.data);
        setIsLoading(false);
      }
      catch(error){
        alert('error on get account');
      }
    }
    getAccounts();
  }, [accountId]);


  const onSubmit = async (data: any) => {
    const response = await Api.put(`/account/${accountId}`, data);
    setAccounts(response.data);
    return(response.data);
  };

  const readOnly = (action === 'view') ? true: false;

  return (
    <Grid container spacing={2}>
      {isLoading ?
        <Grid item sm={12}>
          <LinearProgress />
        </Grid>
      : 
      <>
      
        <IdentificationBlock 
          readOnly={readOnly}
          defaultValues={{identification: account.identification}}
          onSubmit={onSubmit}
        />

        <CommunicationBLock 
          readOnly={readOnly}
          defaultValues={{communication: account.communication}}
          onSubmit={onSubmit}
        />

        <AddressBlock 
          readOnly={readOnly}
          defaultValues={{addresses: account.addresses}}
          onSubmit={onSubmit}
        />

        {/* <LogsBlock 
          readOnly={readOnly}
          defaultValues={{notes: account.notes}}
          onSubmit={onSubmit}
        /> */}

      </>
      }

    </Grid>
  )
}

export default Account;
