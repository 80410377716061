import React, {useState, useEffect} from 'react';
import Api from 'Services/api';
import {
  useHistory,
} from 'react-router-dom';
// import Table from 'components/Table';

import { IoMdContact } from 'react-icons/io';

import { Tab,  RemoveRedEyeOutlined, Edit, OpenInNew, DeleteForever} from '@material-ui/icons';
import SuperTable from 'components/SuperTable';
import IconCard from 'components/IconCard';

import {Link} from 'react-router-dom';

import {Grid, IconButton} from '@material-ui/core';

import Button from 'components/Button';

const Contact: React.FC = () => {

  const history = useHistory();
  const [contacts, setContacts] = useState([]);

  const [loading, setLoading] = useState(false);

  const [isFrozen, setIsFrozen] = useState(false);

  async function getContacts (){
    setLoading(true);
    const response = await Api.get('/contacts');
    setContacts(response.data);
    // console.log(response.data);
    setLoading(false);
  }

  async function createContact(){
    setLoading(true);
    const response = await Api.post('/contact');
    const id = response?.data?.id;
    history.push(`/contact/edit/${id}`);
  }

  async function deleteContact (id: string) {
    setLoading(true);
    const response = await Api.delete(`/contact/${id}`);
    console.log(response);
    getContacts();
  }

  useEffect(() => {
    getContacts();    
  }, []);


  const [columns, setColumns] = useState<any[]>([
    {key: 'firstName', title: 'First Name', dataKey: 'identification.firstName', resizable: true, width: 180},
    {key: 'lastName', title: 'Last Name', dataKey: 'identification.lastName', resizable: true, width: 180},
    {key: 'gender', title: 'Gender', dataKey: 'identification.gender', resizable: true, width: 160},
    {key: 'birthday', title: 'Birthday', dataKey: 'identification.birthday', resizable: true, width: 180},
    {key: 'actions', title: 'Actions', dataKey: '', resizable: false, width: 200, 
    cellRenderer: (data: any) => {
      // console.log(data);
      return (
        <>
          <IconButton
            component={Link}
            to={`/contact/edit/${data.rowData.id}`}
            target="_blank"
          >
              <OpenInNew style={{color: '#ff9800'}} />
          </IconButton>

          <IconButton
            component={Link}
            to={`/contact/view/${data.rowData.id}`}
          >
              <RemoveRedEyeOutlined style={{color: '#00acc1'}} />
          </IconButton>

          <IconButton
            component={Link}
            to={`/contact/edit/${data.rowData.id}`}
          >
              <Edit style={{color: '#4caf50'}} />
          </IconButton>

          <IconButton
            onClick={() => {
              if(data.rowData?.id){
                deleteContact(data.rowData?.id);
              }
            }}
          >
            <DeleteForever color="error" />
          </IconButton>
  
        </>
      )
    }},
  ]);

  const tableHeight = loading ? 204 : 200;

  const frozen = () => {
    if(!isFrozen){
      const newColumns = columns.map(col => {
        if(col.key === 'firstName'){
          return {...col, frozen: 'left'};
        }
        else{
          return col;
        }
      });
      setColumns(newColumns);
      setIsFrozen(true);
    }
    else{
      const newColumns = columns.map(col => {
        if(col.key === 'firstName'){
          let newCol = {...col};
          if(newCol.frozen){
            delete newCol.frozen;
          }
          
          return newCol;
        }
        else{
          return col;
        }
      });
      setColumns(newColumns);
      setIsFrozen(false);
    }
    
  }

  return (
    <>
     <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <IconCard 
            icon={<IoMdContact />}
            iconColor="rose"
            title="Contact"
            margin={false}
            loading={loading}
            addFunction={createContact}
            buttons={
              <>
                <Button 
                  color="primary" 
                  justIcon
                  round
                  aria-label="add"
                  onClick={() => frozen()}
                  disabled={loading}
                  // className={classes.margin}
                >
                  <Tab />
                </Button>
              </>
            }
          >
            <div 
              style={{ 
                maxWidth: '100%', 
                height: `calc(100vh - ${tableHeight}px)`, 
                minHeight: '250px'}}
            >
              <SuperTable 
                columns={columns}
                data={contacts}
              />
            </div>
          </IconCard>
        </Grid>
      </Grid>
    </>
  )
}

export default Contact;