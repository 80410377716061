import React from 'react';
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import styles from './styles';

const useStyles = makeStyles(styles);

interface ICardIcon {
  color? : "warning" | "success" | "danger" | "info" | "primary" | "rose",
  className?: string,
}

const CustomCard: React.FC<ICardIcon> = (props) => {
  const classes = useStyles();

  const {
    className, 
    color,
    children,
    ...rest
  } = props;

  let cardClasses = clsx(classes.cardIcon, true);

  if (color !== undefined) {
    cardClasses += ' ' + clsx(classes[color], true);
  }

  if (className) {
    cardClasses += ' ' + clsx(className, true);
  }

  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  )
}

export default CustomCard;