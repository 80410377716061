import React, { createContext, useState } from "react";
import { User } from '@dsrcloud/types';


const initialState = {
  theme: 'light',
  setTheme: (state: string) => {},
  user: JSON.parse(localStorage.getItem('user') as string) as User,
  setUser: (state: any) => {},
  LogOut: () => {}
}

const MainContext = createContext(initialState);

const MainContextProvider: React.FC = (props) => {

  const { children } = props;

  const [theme, setTheme] = useState(initialState.theme);
  const [user, setUser] = useState(initialState.user);

  const LogOut = function(){
    localStorage.removeItem('user');
  }

  return (
    <MainContext.Provider value={{theme, setTheme, user, setUser, LogOut}} >
      {children}
    </MainContext.Provider>
  )
}

export { MainContext, MainContextProvider };