import { createStyles, Theme } from '@material-ui/core/styles';
import { hexToRgb } from 'utils/themeUtils'; 

const styles = ( {gradientPalette}: Theme) => createStyles( {
  cardHeader: {
    padding: "0.75rem 1.25rem",
    marginBottom: "0",
    borderBottom: "none",
    background: "transparent",
    // zIndex: "3 !important",
    "&$cardHeaderPlain,&$cardHeaderIcon,&$cardHeaderStats,&$warning,&$success,&$danger,&$info,&$primary,&$rose": {
      margin: "0 15px",
      padding: "0",
      position: "relative",
      // color: gradientPalette.whiteColor
    },
    "&:first-child": {
      borderRadius: "calc(.25rem - 1px) calc(.25rem - 1px) 0 0"
    },
    "&$warning,&$success,&$danger,&$info,&$primary,&$rose": {
      "&:not($cardHeaderIcon)": {
        borderRadius: "3px",
        marginTop: "-20px",
        padding: "15px"
      }
    },
    "&$cardHeaderStats svg": {
      fontSize: "36px",
      lineHeight: "56px",
      textAlign: "center",
      width: "36px",
      height: "36px",
      margin: "10px 10px 4px"
    },
    "&$cardHeaderStats i,&$cardHeaderStats .material-icons": {
      fontSize: "36px",
      lineHeight: "56px",
      width: "56px",
      height: "56px",
      textAlign: "center",
      overflow: "unset",
      marginBottom: "1px"
    },
    "&$cardHeaderStats$cardHeaderIcon": {
      textAlign: "right"
    }
  },
  cardHeaderPlain: {
    marginLeft: "0px !important",
    marginRight: "0px !important"
  },
  cardHeaderStats: {
    "& $cardHeaderIcon": {
      textAlign: "right"
    },
    "& h1,& h2,& h3,& h4,& h5,& h6": {
      margin: "0 !important"
    }
  },
  cardHeaderIcon: {
    "&$warning,&$success,&$danger,&$info,&$primary,&$rose": {
      background: "transparent",
      boxShadow: "none"
    },
    "& i,& .material-icons": {
      width: "33px",
      height: "33px",
      textAlign: "center",
      lineHeight: "33px"
    },
    "& svg": {
      width: "24px",
      height: "24px",
      textAlign: "center",
      lineHeight: "33px",
      margin: "5px 4px 0px"
    }
  },
  warning: {
    color: gradientPalette.whiteColor,
    "&:not($cardHeaderIcon)": {
      background:"linear-gradient(60deg, " + gradientPalette.warningColor[1] + ", " + gradientPalette.warningColor[2] + ")",
      boxShadow: "0 4px 20px 0 rgba(" + hexToRgb(gradientPalette.blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(gradientPalette.warningColor[0]) + ",.4)"
    }
  },
  success: {
    color: gradientPalette.whiteColor,
    "&:not($cardHeaderIcon)": {
      background:"linear-gradient(60deg, " + gradientPalette.successColor[1] + ", " + gradientPalette.successColor[2] + ")",
      boxShadow: "0 4px 20px 0 rgba(" + hexToRgb(gradientPalette.blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(gradientPalette.successColor[0]) + ",.4)"
    }
  },
  danger: {
    color: gradientPalette.whiteColor,
    "&:not($cardHeaderIcon)": {
      background: "linear-gradient(60deg, " + gradientPalette.dangerColor[1] + ", " + gradientPalette.dangerColor[2] + ")",
      boxShadow: "0 4px 20px 0 rgba(" + hexToRgb(gradientPalette.blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(gradientPalette.dangerColor[0]) + ",.4)"
    }
  },
  info: {
    color: gradientPalette.whiteColor,
    "&:not($cardHeaderIcon)": {
      background: "linear-gradient(60deg, " + gradientPalette.infoColor[1] + ", " + gradientPalette.infoColor[2] + ")",
      boxShadow: "0 4px 20px 0 rgba(" + hexToRgb(gradientPalette.blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(gradientPalette.infoColor[0]) + ",.4)"
    }
  },
  primary: {
    color: gradientPalette.whiteColor,
    "&:not($cardHeaderIcon)": {
      background: "linear-gradient(60deg, " + gradientPalette.primaryColor[1] + ", " + gradientPalette.primaryColor[2] + ")",
      boxShadow: "0 4px 20px 0 rgba(" + hexToRgb(gradientPalette.blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(gradientPalette.primaryColor[0]) + ",.4)"
    }
  },
  rose: {
    color: gradientPalette.whiteColor,
    "&:not($cardHeaderIcon)": {
      background: "linear-gradient(60deg, " + gradientPalette.roseColor[1] + ", " + gradientPalette.roseColor[2] + ")",
      boxShadow: "0 4px 20px 0 rgba(" + hexToRgb(gradientPalette.blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(gradientPalette.roseColor[0]) + ",.4)"
    }
  }
});

export default styles;