import { createStyles, Theme } from '@material-ui/core/styles';

// const transition = {
//   transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
// };

const styles = ( {drawerWidth, breakpoints, mixins, spacing}: Theme) => createStyles( {
  root: {
    // display: 'flex',
  },
  drawer: {
    [breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: spacing(2),
    [breakpoints.up('md')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: spacing(3),
    [breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
});

export default styles;