import { createStyles, Theme } from '@material-ui/core/styles';
import { hexToRgb } from 'utils/themeUtils';

const styles = ( {gradientPalette, breakpoints, drawerWidth}: Theme) => createStyles({
    appBar: {
        // backgroundColor: "transparent",
        // boxShadow: "none",
        // borderBottom: "0",
        // marginBottom: "0",
        // position: "absolute",
        // width: "100%",
        // paddingTop: "10px",
        // "z-index: "1029",
        color: gradientPalette.grayColor[7],
        // border: "0",
        // borderRadius: "3px",
        // padding: "10px 0",
        // transition: "all 150ms ease 0s",
        // minHeight: "50px",
        display: "block",
        // [breakpoints.up('sm')]: {
          // width: `calc(100% - ${drawerWidth}px)`,
          // marginLeft: drawerWidth,
        // },
      },
      container: {
        // ...container,
        // minHeight: "50px"
      },
      flex: {
        flex: 1
      },
      title: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        "font-weight": "300",
        // lineHeight: "1.5em",
        // ...defaultFont,
        letterSpacing: "unset",
        lineHeight: "30px",
        fontSize: "18px",
        borderRadius: "3px",
        textTransform: "none",
        color: "inherit",
        margin: "0",
        "&:hover,&:focus": {
          background: "transparent"
        }
      },
      appResponsive: {
        top: "8px"
      },
      primary: {
        backgroundColor: gradientPalette.primaryColor[0],
        color: gradientPalette.whiteColor,
        // ...defaultBoxShadow(gradientPalette.blackColor)
        border: "0",
        borderRadius: "3px",
        boxShadow:
            "0 10px 20px -12px rgba(" +
            hexToRgb(gradientPalette.blackColor) +
            ", 0.42), 0 3px 20px 0px rgba(" +
            hexToRgb(gradientPalette.blackColor) +
            ", 0.12), 0 8px 10px -5px rgba(" +
            hexToRgb(gradientPalette.blackColor) +
            ", 0.2)",
        padding: "10px 0",
        transition: "all 150ms ease 0s"
      },
      info: {
        backgroundColor: gradientPalette.infoColor[0],
        color: gradientPalette.whiteColor,
        // ...defaultBoxShadow(gradientPalette.blackColor)
        border: "0",
        borderRadius: "3px",
        boxShadow:
            "0 10px 20px -12px rgba(" +
            hexToRgb(gradientPalette.blackColor) +
            ", 0.42), 0 3px 20px 0px rgba(" +
            hexToRgb(gradientPalette.blackColor) +
            ", 0.12), 0 8px 10px -5px rgba(" +
            hexToRgb(gradientPalette.blackColor) +
            ", 0.2)",
        // padding: "10px 0",
        transition: "all 150ms ease 0s"
      },
      success: {
        backgroundColor: gradientPalette.successColor[0],
        color: gradientPalette.whiteColor,
        // ...defaultBoxShadow(gradientPalette.blackColor)
        border: "0",
        borderRadius: "3px",
        boxShadow:
            "0 10px 20px -12px rgba(" +
            hexToRgb(gradientPalette.blackColor) +
            ", 0.42), 0 3px 20px 0px rgba(" +
            hexToRgb(gradientPalette.blackColor) +
            ", 0.12), 0 8px 10px -5px rgba(" +
            hexToRgb(gradientPalette.blackColor) +
            ", 0.2)",
        padding: "10px 0",
        transition: "all 150ms ease 0s"
      },
      warning: {
        backgroundColor: gradientPalette.warningColor[0],
        color: gradientPalette.whiteColor,
        // ...defaultBoxShadow(gradientPalette.blackColor)
        border: "0",
        borderRadius: "3px",
        boxShadow:
            "0 10px 20px -12px rgba(" +
            hexToRgb(gradientPalette.blackColor) +
            ", 0.42), 0 3px 20px 0px rgba(" +
            hexToRgb(gradientPalette.blackColor) +
            ", 0.12), 0 8px 10px -5px rgba(" +
            hexToRgb(gradientPalette.blackColor) +
            ", 0.2)",
        padding: "10px 0",
        transition: "all 150ms ease 0s"
      },
      danger: {
        backgroundColor: gradientPalette.dangerColor[0],
        color: gradientPalette.whiteColor,
        // ...defaultBoxShadow(gradientPalette.blackColor)
        border: "0",
        borderRadius: "3px",
        boxShadow:
            "0 10px 20px -12px rgba(" +
            hexToRgb(gradientPalette.blackColor) +
            ", 0.42), 0 3px 20px 0px rgba(" +
            hexToRgb(gradientPalette.blackColor) +
            ", 0.12), 0 8px 10px -5px rgba(" +
            hexToRgb(gradientPalette.blackColor) +
            ", 0.2)",
        padding: "10px 0",
        transition: "all 150ms ease 0s"
      }

});

export default styles;
