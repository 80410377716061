import { createStyles, Theme } from '@material-ui/core/styles';
import { hexToRgb } from 'utils/themeUtils'; 

const styles = ( {gradientPalette}: Theme) => createStyles( {
  cardIcon: {
    "&$warning,&$success,&$danger,&$info,&$primary,&$rose": {
      borderRadius: "3px",
      backgroundColor: gradientPalette.grayColor[0],
      padding: "15px",
      marginTop: "-20px",
      marginRight: "15px",
      float: "left"
    }
  },
  warning: {
    color: gradientPalette.whiteColor,
    // "&:not($cardHeaderIcon)": {
      background:"linear-gradient(60deg, " + gradientPalette.warningColor[1] + ", " + gradientPalette.warningColor[2] + ")",
      boxShadow: "0 4px 20px 0 rgba(" + hexToRgb(gradientPalette.blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(gradientPalette.warningColor[0]) + ",.4)"
    // }
  },
  success: {
    color: gradientPalette.whiteColor,
    // "&:not($cardHeaderIcon)": {
      background:"linear-gradient(60deg, " + gradientPalette.successColor[1] + ", " + gradientPalette.successColor[2] + ")",
      boxShadow: "0 4px 20px 0 rgba(" + hexToRgb(gradientPalette.blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(gradientPalette.successColor[0]) + ",.4)"
    // }
  },
  danger: {
    color: gradientPalette.whiteColor,
    // "&:not($cardHeaderIcon)": {
      background: "linear-gradient(60deg, " + gradientPalette.dangerColor[1] + ", " + gradientPalette.dangerColor[2] + ")",
      boxShadow: "0 4px 20px 0 rgba(" + hexToRgb(gradientPalette.blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(gradientPalette.dangerColor[0]) + ",.4)"
    // }
  },
  info: {
    color: gradientPalette.whiteColor,
    // "&:not($cardHeaderIcon)": {
      background: "linear-gradient(60deg, " + gradientPalette.infoColor[1] + ", " + gradientPalette.infoColor[2] + ")",
      boxShadow: "0 4px 20px 0 rgba(" + hexToRgb(gradientPalette.blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(gradientPalette.infoColor[0]) + ",.4)"
    // }
  },
  primary: {
    color: gradientPalette.whiteColor,
    // "&:not($cardHeaderIcon)": {
      background: "linear-gradient(60deg, " + gradientPalette.primaryColor[1] + ", " + gradientPalette.primaryColor[2] + ")",
      boxShadow: "0 4px 20px 0 rgba(" + hexToRgb(gradientPalette.blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(gradientPalette.primaryColor[0]) + ",.4)"
    // }
  },
  rose: {
    color: gradientPalette.whiteColor,
    // "&:not($cardHeaderIcon)": {
      background: "linear-gradient(60deg, " + gradientPalette.roseColor[1] + ", " + gradientPalette.roseColor[2] + ")",
      boxShadow: "0 4px 20px 0 rgba(" + hexToRgb(gradientPalette.blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(gradientPalette.roseColor[0]) + ",.4)"
    // }
  }
});

export default styles;