import React, {useState} from 'react';
import Button from 'components/Button';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import styles from './styles';
import Typography from '@material-ui/core/Typography';
import { useForm } from "react-hook-form";
import { TextField } from '@material-ui/core';
import Swal from 'components/Swal2';
import { useHistory } from "react-router-dom";

import {
  LinearProgress
} from '@material-ui/core';

import Header from 'components/Header';

import Api from 'Services/api'

const useStyles = makeStyles(styles);

const Register: React.FC = () => {
  const classes = useStyles();

  const { handleSubmit, register } = useForm();

  const [loading, setLoading] = useState(false);

  // history.push('/login');
  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const response = await Api.post('/register', data)
      console.log(response);
      Swal.fire({
        title: 'Registered!',
        text: 'Check your email and click on confirm email',
        icon: 'success',
        confirmButtonText: 'OK',
        onClose: () => {
          history.push('/login');
        },

      })
    }
    catch(error){
      Swal.fire({
        title: 'Error!',
        text: JSON.stringify(error?.response?.data?.error),
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonProps: {color: 'info', round: true}
      })
    }
    finally{
      setLoading(false);
    }
  }

  let history = useHistory();

  return (
    <div
      className={classes.pageHeader}
      style={{
        backgroundImage: "url(/assets/img/bg20.jpg)",
        // backgroundImage: "url(https://source.unsplash.com/random?mountain)",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    >

  <div className={classes.container}>
    <Header 
      color="transparent"
      absolute
      brand={<img src="assets/img/logo.png" alt="logo" height="45px"/>}
      rightLinks={
        <Button 
          fullWidth 
          round
          color="info"
          onClick={() => {
            history.push('/login');
          }}
        >
          Login
        </Button>
      }
    />
    <Grid
      container
      justify="center"
    >
      <Grid item xs={12} sm={12} md={4}>
        <Card>
          <CardHeader color="info"  className={classes.cardHeader}>
            <Typography variant="h5" style={{margin: "8px"}}>Register</Typography>

          </CardHeader>
          <CardBody>
            <form 
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >

              <TextField 
                fullWidth
                autoFocus
                variant="outlined"
                label="First Name"
                name="firstName" 
                inputRef={register}
                style={{marginTop:"12px"}}
              />

              <TextField 
                fullWidth
                variant="outlined"
                label="Last Name"
                name="lastName" 
                inputRef={register}
                style={{marginTop:"15px"}}
              />

              <TextField 
                fullWidth
                variant="outlined"
                label="Email"
                name="email" 
                inputRef={register}
                style={{marginTop:"15px"}}
              />

              <TextField 
                fullWidth
                variant="outlined"
                label="Username"
                name="username" 
                inputRef={register}
                style={{marginTop:"15px"}}
              />

              <TextField 
                fullWidth
                variant="outlined"
                label="Password"
                name="password"
                type="password" 
                inputRef={register}
                style={{marginTop:"15px"}}
              />

              <TextField 
                fullWidth
                variant="outlined"
                label="Confirm Password"
                name="confirmPassword"
                type="password" 
                inputRef={register}
                style={{marginTop:"15px"}}
              />
              

              <div style={{marginTop:"15px"}}>
                
              <Button 
                type="submit"
                color="info"
                fullWidth
                round
                disabled={loading}
              >
                Register
              </Button>
              </div>
            </form>
            {loading && 
              <>
                <LinearProgress style={{marginTop: '10px'}}/>
              </>
            }
          </CardBody>
        </Card>
      </Grid>
    </Grid>
    </div>
    </div>

  )
}

export default Register;
//firstName
//last name
//email
//username
//password