import { createMuiTheme } from '@material-ui/core/styles';
// import { Theme } from '@material-ui/core/styles/createMuiTheme';
// import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    colorPallete: {
      primaryColor : string,
      warningColor : string,
      dangerColor : string,
      successColor : string,
      infoColor : string,
      roseColor : string,
      grayColor : string,
    },
    drawerWidth: number,
    gradientPalette: {
      primaryColor : [string, string, string, string],
      warningColor : [string, string, string, string],
      dangerColor : [string, string, string, string],
      successColor : [string, string, string, string],
      infoColor : [string, string, string, string],
      roseColor : [string, string, string, string],
      grayColor : [
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string
      ],
      blackColor : string,
      whiteColor : string,
    }
  }
  // permitir configuração usando `createMuiTheme`
  interface ThemeOptions {
    colorPallete: {
      primaryColor : string,
      warningColor : string,
      dangerColor : string,
      successColor : string,
      infoColor : string,
      roseColor : string,
      grayColor : string,
    },
    drawerWidth: number,
    gradientPalette: {
      primaryColor : [string, string, string, string],
      warningColor : [string, string, string, string],
      dangerColor : [string, string, string, string],
      successColor : [string, string, string, string],
      infoColor : [string, string, string, string],
      roseColor : [string, string, string, string],
      grayColor : [
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string
      ],
      blackColor : string,
      whiteColor : string,
    }
  }
}

const theme = createMuiTheme({

  palette: {
    // type: 'dark',
    action: {
      // disabledBackground: '#808080',
      // disabled: '#808080'
    },
    background: {
      default: "#eeeeee"
    },
    primary: {
      main: "#00acc1"
    },
  },
  drawerWidth: 260,
  colorPallete: {
    primaryColor : "#9c27b0",
    warningColor : "#ff9800",
    dangerColor : "#f44336",
    successColor : "#4caf50",
    infoColor : "#00acc1",
    roseColor : "#e91e63",
    grayColor : "#999999",
  },
  gradientPalette: {
    primaryColor : ["#9c27b0", "#ab47bc", "#8e24aa", "#af2cc6"],
    warningColor : ["#ff9800", "#ffa726", "#fb8c00", "#ffa21a"],
    dangerColor : ["#f44336", "#ef5350", "#e53935", "#f55a4e"],
    successColor : ["#4caf50", "#66bb6a", "#43a047", "#5cb860"],
    infoColor : ["#00acc1", "#26c6da", "#00acc1", "#00d3ee"],
    roseColor : ["#e91e63", "#ec407a", "#d81b60", "#eb3573"],
    grayColor : [
      "#999",
      "#777",
      "#3C4858",
      "#AAAAAA",
      "#D2D2D2",
      "#DDD",
      "#b4b4b4",
      "#555555",
      "#333",
      "#a9afbb",
      "#eee",
      "#e7e7e7"
    ],
    blackColor : "#000",
    whiteColor : "#FFF",
  }
});

const DarkTheme = createMuiTheme({
  // direction: 'rtl',
  palette: {
    type: 'dark',
    primary: {
      main: "#00acc1"
    },
  },
  drawerWidth: 260,
  colorPallete: {
    primaryColor : "#9c27b0",
    warningColor : "#ff9800",
    dangerColor : "#f44336",
    successColor : "#4caf50",
    infoColor : "#00acc1",
    roseColor : "#e91e63",
    grayColor : "#999999",
  },
  gradientPalette: {
    primaryColor : ["#9c27b0", "#ab47bc", "#8e24aa", "#af2cc6"],
    warningColor : ["#ff9800", "#ffa726", "#fb8c00", "#ffa21a"],
    dangerColor : ["#f44336", "#ef5350", "#e53935", "#f55a4e"],
    successColor : ["#4caf50", "#66bb6a", "#43a047", "#5cb860"],
    infoColor : ["#00acc1", "#26c6da", "#00acc1", "#00d3ee"],
    roseColor : ["#e91e63", "#ec407a", "#d81b60", "#eb3573"],
    grayColor : [
      "#999",
      "#777",
      "#3C4858",
      "#AAAAAA",
      "#D2D2D2",
      "#DDD",
      "#b4b4b4",
      "#555555",
      "#333",
      "#a9afbb",
      "#eee",
      "#e7e7e7"
    ],
    blackColor : "#000",
    whiteColor : "#FFF",
  }
});

export default theme;
export { theme, DarkTheme};