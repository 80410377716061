import { createStyles, Theme } from '@material-ui/core/styles';
// import { hexToRgb } from 'utils/themeUtils';

const styles = ( {gradientPalette, palette, spacing}: Theme) => createStyles({
  margin: {
    // margin: spacing(1),
    marginLeft: spacing(1)
  },
  cardCategory: {
    // color: "black",
    // color: "#3C4858",
    fontSize: "20px",
    "font-weight": "300",
    lineHeight: "1",
    marginBottom: "0",
    // position: 'absolute',

    // paddingTop: '10px',
    // marginTop: '20px'
    // bottom: "0",
  },
  bgPaper: {
    // "& > *": {
    //   background: palette.background.paper,
    // }
  }
});

export default styles;
