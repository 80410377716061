import React from 'react';
// import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {DialogProps} from '@material-ui/core/Dialog';
import Zoom from '@material-ui/core/Zoom';
import Button from 'components/Button';
// import styles from './styles';
import Grid from '@material-ui/core/Grid';

import { Typography} from '@material-ui/core';

// import AnimatedSuccessIcon from './AnimatedSuccessIcon';
import SuccessAnimation from './animations/Success';
import WarningAnimation from './animations/Warning';
import ErrorAnimation   from './animations/Error';
// const useStyles = makeStyles(styles);


const CustomDialog: React.FC<DialogProps> = (props) => {
  // const classes = useStyles();


  const {
    // color,
    open,
    onClose,
    // ...rest
  } = props;

  // function handleDialog(){
  //   onClose();
  // } 

  return (
    <>
      <Dialog 
        open={open} 
        onClose={onClose}
        TransitionComponent={Zoom}
        // transitionDuration={300}
      >
        <DialogContent>
          <Grid container justify="center" direction="column" alignItems="center">
            <Grid item sm={12}>
              <div>
              <SuccessAnimation/>
              <WarningAnimation/>
              <ErrorAnimation />
              </div>
            </Grid>
            <Grid item sm={12}>
              <Typography>hdjsakhdkjasdkj hdjsakhdkjasdkj hdjsakhdkjasdkj</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        
        <DialogActions >
          {/* <Button color="danger" onClick={onClose}>Cancel</Button> */}
          <Button color="success">Confirm</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CustomDialog;