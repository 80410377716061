import React from 'react';
import clsx from 'clsx'; 
import { makeStyles } from "@material-ui/core/styles";
import styles from './styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(styles);

interface ICard {
  className? : string;
  plain? : boolean;
  profile?: boolean;
  chart?: boolean;
  bgDarker?: boolean;
  style?: React.CSSProperties;
}

const CustomCard: React.FC<ICard> = (props) => {
  const classes = useStyles();

  const {
    className, 
    children,
    plain,
    profile,
    chart,
    bgDarker,
    ...rest
  } = props;

  let cardClasses = clsx({
    [classes.card]: true,
    [classes.cardPlain]: plain,
    [classes.cardProfile]: profile,
    [classes.cardChart]: chart,
    [classes.bgDarker]: bgDarker,
  });

  if (className) {
    cardClasses += '' + clsx(className, true);
  }

  return (
    <Paper className={cardClasses} {...rest}>
      {children}
    </Paper>
  )
}

export default CustomCard;