import React from 'react';
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import styles from './styles';

const useStyles = makeStyles(styles);

interface ICard {
  className?: string,
  plain? : boolean,
  profile?: boolean,
}

const CustomCard: React.FC<ICard> = (props) => {
  const classes = useStyles();

  const {
    className, 
    plain,
    profile,
    children,
    ...rest
  } = props;

  let cardClasses = clsx({
    [classes.cardBody]: true,
    [classes.cardBodyPlain]: plain,
    [classes.cardBodyProfile]: profile,
  });

  if (className) {
    cardClasses += ' ' + clsx(className, true);
  }

  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  )
}

export default CustomCard;