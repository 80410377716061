// import React, { ReactElement } from 'react';
import React from 'react';
import Swal, { SweetAlertResult } from 'sweetalert2';
import withReactContent, {ReactSweetAlertOptions} from 'sweetalert2-react-content';
import { ThemeProvider } from '@material-ui/styles';
import Button, {ButtonExtended} from 'components/Button';
import Theme from 'Theme';

import './style.css';

interface ButtonProps extends ReactSweetAlertOptions {
  confirmButtonProps?: ButtonExtended,
  cancelButtonProps?: ButtonExtended,
}

class Swal2 {

  public fire( options: ButtonProps) : Promise<SweetAlertResult> {

    const swalNoStyle = Swal.mixin({
      customClass: {
        confirmButton: '',
        cancelButton: ''
      },
      buttonsStyling: false,
    });

    const swal = withReactContent(swalNoStyle);
    const {title, html, confirmButtonText, cancelButtonText, footer, confirmButtonProps, cancelButtonProps, ...rest} = options;
    
    let config : any = {...rest};

    if (title) {
      config.title = <ThemeProvider theme={Theme}>{title}</ThemeProvider>;
    }

    if (html) {
      config.html = <ThemeProvider theme={Theme}>{html}</ThemeProvider>;
    }

    if (footer) {
      config.footer = <ThemeProvider theme={Theme}>{footer}</ThemeProvider>;
    }

    if (confirmButtonText) {
      config.confirmButtonText = <ThemeProvider theme={Theme}><Button color="success" component="div" {...confirmButtonProps} >{confirmButtonText}</Button></ThemeProvider>;
    }

    if (cancelButtonText) {
      config.cancelButtonText = <ThemeProvider theme={Theme}><Button color="danger" component="div" {...cancelButtonProps} >{cancelButtonText}</Button></ThemeProvider>;
    }

    return swal.fire(config);

  }

}

export default new Swal2();

// Material-ui themes, and react components