import React, {useState} from 'react';
// import Button from "components/Button";
import Grid from '@material-ui/core/Grid';
// import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from "@material-ui/core/styles";
import styles from './styles';

import {
  TextField,
  MenuItem,
  // SvgIcon,
  // Typography
} from '@material-ui/core';

import IconCard from 'components/IconCard';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {FaPhone} from 'react-icons/fa';
// import { Add, Restore, Save, DeleteForever } from '@material-ui/icons';

import Complement from './Complement';

const useStyles = makeStyles(styles);

interface CommunicationBlock {
  readOnly?: boolean,
  defaultValues?: any,
  onSubmit?: Function
}

const Communication: React.FC<CommunicationBlock> = (props) => {
  const classes = useStyles();
  const {
    defaultValues,
    readOnly,
    onSubmit,
  } = props;

  const [communicationOptions] = useState([
    'Phone',
    'Mobile',
    'Website',
    'Email',
    'Facebook',
    'Instagram',
    'Linkedin',
    'Telegram',
    'Whatsapp',
    'Youtube',
  ]);


  const { register, handleSubmit, formState, reset, control, watch } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const { fields, append, remove} = useFieldArray({
    control,
    name: "communication",
  });

  const arrayWatch = watch('communication');

  const submit = async (data: any) => {
    // alert(JSON.stringify(data));
    if (onSubmit) {
      try {
        let response = {};
        if(data?.communication?.length > 0){
          response = await onSubmit(data);
        }
        else{
          response = await onSubmit({communication: []});
        }
        reset(response);
      }
      catch(error){
        alert('error');
      }
    }
  }
  

  const fieldSize = 'small';
  return (
    <IconCard 
      icon={<FaPhone />}
      iconColor="success"
      title="Communication"
      loading={formState.isSubmitting}
      readOnly={readOnly}
      isDirty={formState.isDirty}
      addFunction={() => append({ type: "", value: "" })}
      resetFunction={ () => reset() }
      saveFunction={() => handleSubmit(submit)() }
      
    >
      <form onSubmit={handleSubmit(submit)}>
        <Grid container spacing={2}>
        {fields.map((item, index) => { 
          return(
          <Grid item xs={6} xl={4} key={item.id}>
          <IconCard
            loading={formState.isSubmitting}
            readOnly={readOnly}
            title={`${arrayWatch[index]?.type}`}
            bgDarker 
            removeFunction={  () =>  remove(index) }
            
          >
          <Grid container spacing={2} >
          

            <Grid item xs={12} sm={4}>
              <Controller 
                select
                label="Type"
                control={control}
                variant="outlined"
                InputProps={{ readOnly }}
                name={`communication[${index}].type`}
                fullWidth
                size={fieldSize}
                defaultValue={item.type}
                className={classes.bgPaper}
                as={
                  <TextField>
                    <MenuItem value=""></MenuItem>
                    {communicationOptions.map( option => (
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                  </TextField>
                }
              />
            </Grid>

            <Grid item xs={12} sm={8}>
              <TextField 
                variant="outlined"
                fullWidth
                label={`Type Value`}
                size={fieldSize}
                inputRef={register()}
                name={`communication[${index}].value`}
                InputProps={{ readOnly }}
                defaultValue={item.value}
                className={classes.bgPaper}
              />
            </Grid>

              <Complement 
                control={control}
                register={register}
                index={index}
                readOnly={readOnly}
                loading={formState.isSubmitting}
              />            
              
          </Grid>
          </IconCard>
          </Grid>
        )})}

      </Grid>
      </form>

    </IconCard>
    
  )
}

export default Communication;