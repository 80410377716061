import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';

import {
  TextField,
  MenuItem
} from '@material-ui/core';

import IconCard from 'components/IconCard';
import { useForm, Controller } from "react-hook-form";
import {AiOutlineFileSearch} from 'react-icons/ai';

interface IIdentificationAgreementService {
  readOnly?: boolean,
  defaultValues?: any,
  onSubmit?: Function
}

const IdentificationAgreementService: React.FC<IIdentificationAgreementService> = (props) => {
  // const classes = useStyles();

  const {
    defaultValues,
    readOnly,
    onSubmit,
    // ...rest
  } = props;


  const { handleSubmit, formState, reset, control } = useForm({
    mode: 'onChange',
    defaultValues,
  });


  const [loading, setLoading] = useState(false);

  // const [ serviceTypes ] = useState(["Burglar Alarm Monitoring", "Fire Alarm Monitoring"]);

  const [types] = useState({
    serviceName: [
      'Audio Cabling Consulting',
      'Audio Cabling Contract',
      'Audio Cabling Design',
      'Audio Cabling Installation',
      'Audio Cabling Service',
      'Audio Video System Consulting',
      'Audio Video System Contract',
      'Audio Video System Design',
      'Audio Video System Installation',
      'Audio Video System Service',
      'Automation Com Consulting',
      'Automation Com Installation',
      'Automation Commercial Contract',
      'Automation Commercial Design',
      'Automation Commercial Service',
      'Automation Res Consulting',
      'Automation Res Contract',
      'Automation Res Design',
      'Automation Res Installation',
      'Automation Res Service',
      'BDA System Consulting',
      'BDA System Contract',
      'BDA System Design',
      'BDA System Installation',
      'BDA System Monitoring',
      'BDA System Service',
      'Broadband Internet Consulting',
      'Broadband Internet Contract',
      'Broadband Internet Design',
      'Broadband Internet Installation',
      'Broadband Internet Monitoring',
      'Broadband Internet Service',
      'Cameras Cabling Consulting',
      'Cameras Cabling Contract',
      'Cameras Cabling Design',
      'Cameras Cabling Installation',
      'Cameras Cabling Service',
      'CCTV System Consulting',
      'CCTV System Contract',
      'CCTV System Design',
      'CCTV System Installation',
      'CCTV System Monitoring',
      'CCTV System Service',
      'DAC System Consulting',
      'DAC System Contract',
      'DAC System Design',
      'DAC System Installation',
      'DAC System Service',
      'Data Cabling Consulting',
      'Data Cabling Contract',
      'Data Cabling Design',
      'Data Cabling Installation',
      'Data Cabling Service',
      'Fire Alarm System Consulting',
      'Fire Alarm System Contract',
      'Fire Alarm System Design',
      'Fire Alarm System Installation',
      'Fire Alarm System Monitoring',
      'Fire Alarm System Service',
      'Intercom PA System Consulting',
      'Intercom PA System Contract',
      'Intercom PA System Design',
      'Intercom PA System Installation',
      'Intercom PA System Service',
      'Intrusion System Consulting',
      'Intrusion System Contract',
      'Intrusion System Design',
      'Intrusion System Installation',
      'Intrusion System Monitoring',
      'Intrusion System Service',
      'Telco System Consulting',
      'Telco System Contract',
      'Telco System Design',
      'Telco System Installation',
      'Telco System Service',
      'Voice Cabling Consulting',
      'Voice Cabling Contract',
      'Voice Cabling Design',
      'Voice Cabling Installation',
      'Voice Cabling Service',
    ]
  });

  const submit = async (data: any) => {
    setLoading(true);
    if (onSubmit) {
      try {
        const response = await onSubmit(data);
        reset(response);
      }
      catch(error){
        alert('error');
      }
      finally{
        setLoading(false);
      }
    }
  }

  const fieldSize = 'small';
  return (
    <IconCard 
      icon={<AiOutlineFileSearch />}
      iconColor="info"
      title="Identification"
      readOnly={readOnly}
      isDirty={formState.isDirty}
      resetFunction={reset}
      saveFunction={ () => handleSubmit(submit)() }
      loading={loading}
    >
      <form onSubmit={handleSubmit(submit)}>
        <Grid container spacing={2}>

          <Grid item xs={12} sm={12}>
          <Controller 
              select
              label="Service Name"
              control={control}
              variant="outlined"
              InputProps={{ readOnly }}
              name="identification.serviceName"
              // error={errors?.billingFeatures?.terms ? true : false}
              // helperText={errors?.billingFeatures?.terms?.message}
              fullWidth
              size={fieldSize}
              // defaultValue=""
              as={
                <TextField>
                  <MenuItem value=""></MenuItem>
                  {types.serviceName.map( item => (
                    <MenuItem key={item} value={item}>{item}</MenuItem>
                  ))}
                </TextField>
              }
            />
          </Grid>


        </Grid>
      </form>
    </IconCard>
  )
}

export default IdentificationAgreementService;