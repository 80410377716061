import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from 'components/Button';
import TextField from '@material-ui/core/TextField';
import IconCard from 'components/IconCard';
// import CardBody from 'components/Card/CardBody';
import { useForm } from "react-hook-form";
import {FaLock} from 'react-icons/fa';
import Api from 'Services/api';

const Profile: React.FC = () => {

  const { handleSubmit, register, errors, reset } = useForm();

  const onSubmit = async (data: any) => {
    try {
      const response = await Api.post('/change_password', data);
      console.log(response);
      reset();
    }
    catch(error){
      console.log('deu ruim ao trocar senha');
    }
  }

  const passwordValidation = {
    required: {value: true, message: "Required!"},
    minLength: {value: 6, message: "Min length is 6"}
    
  }
  
  return (
    <Grid container spacing={2}>
      {/* <Grid item sm={7} xs={12}>
        <IconCard 
          title="Information"
          icon={<FaLock />}
          iconColor="success"
        >
          <CardBody>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <TextField 
                  variant="outlined"
                  fullWidth
                  label="First Name"
                  value=""
                  size='small'
                  // name="firstName"
                  // inputRef={register}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField 
                  variant="outlined"
                  fullWidth
                  label="Last Name"
                  value=""
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField 
                  variant="outlined"
                  fullWidth
                  label="Email"
                  value=""
                  size='small'
                />
              </Grid>
            </Grid>
          </CardBody>
        </IconCard>
      </Grid> */}



      <Grid item xs={12} sm={6}>
        <IconCard 
          title="Change Password"
          icon={<FaLock />}
          iconColor="danger"
        >
            <form 
              // className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField 
                    variant="outlined"
                    fullWidth
                    size='small'
                    label="Current Password"
                    name="currentPassword" 
                    inputRef={register(passwordValidation)}
                    error={errors.current_password ? true : false}
                    helperText={errors?.current_password?.message}
                    type="password"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField 
                    variant="outlined"
                    fullWidth
                    label="New Password"
                    size='small'
                    name="newPassword" 
                    inputRef={register(passwordValidation)}
                    error={errors.new_password ? true : false}
                    helperText={errors?.new_password?.message}
                    type="password"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField 
                    variant="outlined"
                    fullWidth
                    label="Confirm Password"
                    size='small'
                    name="confirmPassword" 
                    inputRef={register(passwordValidation)}
                    error={errors.confirm_password ? true : false}
                    helperText={errors?.confirm_password?.message}
                    type="password"
                  />
                </Grid>
                <Grid item xs={12} sm={12} >
                  <Button color="danger" type="submit">Change Password</Button>
                </Grid>
              </Grid>
            </form>
        </IconCard>
      </Grid>



    </Grid>
  )
}

export default Profile;
// current password
// new password
// confirm password