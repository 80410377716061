import { createStyles, Theme } from '@material-ui/core/styles';

const styles = ( {gradientPalette, palette}: Theme) => createStyles( {
  bgPaper: {
    background: palette.background.paper,
    // background: '#F5FFFA'
    // "& > div > div": {
    //   background: palette.background.paper,
    // }
  },
});

export default styles;