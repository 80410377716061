import React from 'react';
import styles from './styles';
import Card from 'components/Card/Card';
import CardBody from  'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import CardFooter from 'components/Card/CardFooter';

import Grid from '@material-ui/core/Grid';

import Button from 'components/Button';

import {
  LinearProgress,
  Typography,
  Collapse,
  IconButton,
  makeStyles,
  // Tooltip,
  // Fab
} from '@material-ui/core';

import {MdKeyboardArrowUp, MdKeyboardArrowDown} from 'react-icons/md';
import { Add, Restore, Save, DeleteForever } from '@material-ui/icons';

const useStyles = makeStyles(styles);


interface IIconCard {
  iconColor?: "rose" | "warning" | "success" | "danger" | "info" | "primary";
  icon?: React.ReactNode;
  title?: React.ReactNode;
  buttons?: React.ReactNode;
  footer?: React.ReactNode;
  bgDarker?: boolean;
  loading?: boolean;
  readOnly?: boolean;
  isDirty?: boolean;
  noIcon?: boolean;
  addFunction?: Function;
  removeFunction?: Function;
  resetFunction?: Function;
  saveFunction?: Function;
  cardStyle?: React.CSSProperties;
  margin?: boolean;
}

const IconCard: React.FC<IIconCard> = (props) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(true);

  const {
    title,
    icon,
    iconColor,
    buttons,
    footer,
    loading,
    children, 
    bgDarker,
    addFunction,
    removeFunction,
    resetFunction,
    saveFunction,
    readOnly,
    isDirty,
    cardStyle,
    margin,
  } = props;

  const shoudHaveCardBody = margin !== undefined ? margin : true;

  return (
    <Card bgDarker={bgDarker} style={cardStyle}>
    <CardHeader icon>
      <CardIcon color={iconColor}>
        {icon}
      </CardIcon>

      <div style={{flexGrow: 1, float: 'left', background: '',width: `calc(100% - ${icon ? 77 : 0}px)`}}>

        <Grid 
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid container item xs spacing={1}>
            
            <Grid item>
            <div style={{marginTop: "18px"}}>
              <Typography 
                variant="body1" 
                component="a" 
                className={classes.cardCategory}
              >
                {title}
              </Typography>
            </div>
            </Grid>


            <Grid item>
            { ( addFunction && !readOnly) && 
                  <Button 
                    color="rose" 
                    justIcon
                    round
                    aria-label="add"
                    onClick={() => addFunction()}
                    disabled={loading}
                    className={classes.margin}
                  >
                    <Add />
                  </Button>
            }
          </Grid>

          </Grid>

          

          <Grid item>
          {!readOnly && 
            <>
              {/* <ActionButtons /> */}

              {/* TODO remove  */}
              {buttons}

              { isDirty && 
                <>
                  { resetFunction && 
                        <IconButton 
                          style={{color: '#ff9800'}}
                          // color="warning"
                          // simple
                          // justIcon
                          // round
                          disabled={loading}
                          onClick={() => resetFunction()}
                        >
                          <Restore /> 
                        </IconButton>
                  }
          
                  { saveFunction && 
                        <IconButton 
                          style={{color: '#4caf50'}}
                          // color="success"
                          // simple
                          // justIcon
                          // round
                          disabled={loading}
                          onClick={() => saveFunction()}
                        >
                          <Save /> 
                        </IconButton>
                  }
                </>
              }

              { removeFunction && 
                <IconButton 
                  // color="danger"
                  // simple
                  // justIcon
                  // round
                  style={{color: '#f44336'}}
                  disabled={loading}
                  onClick={() => removeFunction()}
                >
                  <DeleteForever/> 
                </IconButton>
              }


              <IconButton 
                onClick={() => { setOpen(!open);}}
              >
                { (open === true) ? <MdKeyboardArrowDown/> : <MdKeyboardArrowUp /> }
              </IconButton>
            </>
          }
          </Grid>

        </Grid>
      </div>

     



    </CardHeader>
    <Collapse in={open}>

      
      {shoudHaveCardBody ? 
        <CardBody className={classes.bgPaper}>
          { loading && <LinearProgress /> }
          {children}
        </CardBody>
      : 
        <>
          { loading && <LinearProgress /> }
          {children}
        </>
      }

      {/* <CardBody className={classes.bgPaper}>
        { loading && <LinearProgress /> }
        {children}
        
      </CardBody> */}
      {footer && (
        <CardFooter>
        {footer}
      </CardFooter>
      )} 

      
    </Collapse>
  </Card>
  )
}

export default IconCard;


