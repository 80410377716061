import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
// import Collapse from '@material-ui/core/Fade';
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  // MenuItem
} from '@material-ui/core';

import IconCard from 'components/IconCard';
import { useForm} from "react-hook-form";
import {FaLocationArrow} from 'react-icons/fa';
import styles from './styles';

interface AddressBlock {
  readOnly?: boolean,
  defaultValues?: any,
  onSubmit?: Function
}
const useStyles = makeStyles(styles);

const AddressBlockComponent: React.FC<AddressBlock> = (props) => {
  const classes = useStyles();
  const {
    defaultValues,
    readOnly,
    onSubmit,
  } = props;

  // const [adressTypeOptions, setAddresTypeOptions] = useState([
  //   'Billing', 
  //   'Mailing', 
  //   'Delivery', 
  //   'Branch', 
  //   'Subsidiary' , 
  //   'Propriety', 
  //   'Jobsite'
  // ]);

  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, formState, reset } = useForm({
    mode: 'onChange',
    defaultValues,
  });



  // const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
  //   control,
  //   name: "addresses",
  // });

  // const arrayWatch = watch('addresses');
  
  // const { fields: childFields, append: appendChild } = useFieldArray({ control, name: 'fields'});

  const submit = async (data: any) => {
    // alert(JSON.stringify(data, null, 2));
    setLoading(true);
    if (onSubmit) {
      try {
        const response = await onSubmit(data);
        // let response = {};
        // if(data?.address?.length > 0) {
        //   response = await onSubmit(data);
        // }
        // else{
        //   response = await onSubmit({addresses: []});
        // }
        
        reset(response);
      }
      catch(error){
        alert('error');
      }
      finally{
        setLoading(false);
      }
    }
  }


  const fieldSize = 'small';
  return (
    <>
    <IconCard 
      icon={<FaLocationArrow />}
      iconColor="primary"
      title="Jobsite Address"
      // addFunction={() => prepend({ type: "", value: "" })}
      isDirty={formState.isDirty}
      readOnly={readOnly}
      resetFunction={reset}
      saveFunction={ () => handleSubmit(submit)()}
      loading={loading}
    >
    

      <form onSubmit={handleSubmit(submit)}>
        
        {/* {fields.map((item, index) => (
          <IconCard 
          readOnly={readOnly}
          title={`${arrayWatch[index]?.type}`}
          bgDarker
          removeFunction={() => remove(index)}
          key={item.id} 
        > */}
  
          <Grid container spacing={1}
            direction="row"  
          >
          
            
            {/* <Grid item xs={12} sm={3} >
              <Controller 
                select
                label="Type"
                control={control}
                variant="outlined"
                InputProps={{ readOnly }}
                name={`address.type`}
                fullWidth
                size={fieldSize}
                // defaultValue={item.type}
                defaultValue=""
                className={classes.bgPaper}
                as={
                  <TextField className={classes.bgPaper}>
                    <MenuItem value=""></MenuItem>
                    {adressTypeOptions.map( type  => (
                      <MenuItem key={type} value={type}>{type}</MenuItem>
                    ))}
                  </TextField>
                }
              />
            </Grid> */}

            <Grid item xs={12} sm={4} >
              <TextField 
                variant="outlined"
                fullWidth
                label={`Property Name`}
                size={fieldSize}
                inputRef={register()}
                name={`address.propertyName`}
                InputProps={{ readOnly }}
                // defaultValue={item.propertyName}
                defaultValue=""
                className={classes.bgPaper}
              />
            </Grid>

            <Grid item xs={12} sm={5} >
              <TextField 
                variant="outlined"
                fullWidth
                label={`Address`}
                size={fieldSize}
                inputRef={register()}
                name={`address.address`}
                InputProps={{ readOnly }}
                // defaultValue={item.address}
                defaultValue=""
                className={classes.bgPaper}
              />
            </Grid>

            <Grid item xs={12} sm={3} >
              <TextField 
                variant="outlined"
                fullWidth
                label={`Address 2`}
                size={fieldSize}
                inputRef={register()}
                name={`address.address2`}
                InputProps={{ readOnly }}
                // defaultValue={item.address2}
                defaultValue=""
                className={classes.bgPaper}
              />
            </Grid>

            

            <Grid item xs={12} sm={3} >
              <TextField 
                variant="outlined"
                fullWidth
                label={`City`}
                size={fieldSize}
                inputRef={register()}
                name={`address.city`}
                InputProps={{ readOnly }}
                // defaultValue={item.city}
                defaultValue=""
                className={classes.bgPaper}
              />
            </Grid>

            {/* <Grid item xs={12} sm={3} >
              <TextField 
                variant="outlined"
                fullWidth
                label={`County`}
                size={fieldSize}
                inputRef={register()}
                name={`addresses[${index}].county`}
                InputProps={{ readOnly }}
                defaultValue={item.county}
                className={classes.bgPaper}
              />
            </Grid> */}


            <Grid item xs={12} sm={3} >
              <TextField 
                variant="outlined"
                fullWidth
                label={`State`}
                size={fieldSize}
                inputRef={register()}
                name={`address.state`}
                InputProps={{ readOnly }}
                // defaultValue={item.state}
                defaultValue=""
                className={classes.bgPaper}
              />
            </Grid>

            <Grid item xs={12} sm={3} >
              <TextField 
                variant="outlined"
                fullWidth
                label={`Zip Code`}
                size={fieldSize}
                inputRef={register()}
                name={`address.zipCode`}
                InputProps={{ readOnly }}
                // defaultValue={item.zipCode}
                defaultValue=""
                className={classes.bgPaper}
              />
            </Grid>

            <Grid item xs={12} sm={3} >
              <TextField 
                variant="outlined"
                fullWidth
                label={`Country`}
                size={fieldSize}
                inputRef={register()}
                name={`address.country`}
                InputProps={{ readOnly }}
                // defaultValue={item.country}
                defaultValue=""
                className={classes.bgPaper}
              />
            </Grid>

            
            <Grid item xs={12} sm={12} >
              <TextField 
                variant="outlined"
                fullWidth
                label={`Near By`}
                size={fieldSize}
                inputRef={register()}
                name={`address.nearBy`}
                InputProps={{ readOnly }}
                // defaultValue={item.nearBy}
                defaultValue=""
                className={classes.bgPaper}
              />
            </Grid>

            <Grid item xs={12} sm={12} >
              <TextField 
                variant="outlined"
                fullWidth
                label={`Google Directions`}
                size={fieldSize}
                inputRef={register()}
                name={`address.googleDirections`}
                InputProps={{ readOnly }}
                // defaultValue={item.googleDirections}
                defaultValue=""
                className={classes.bgPaper}
              />
            </Grid>


          </Grid>

          {/* </IconCard> */}
        {/* ))} */}


      </form>

    </IconCard>
    </>
  )
}

export default AddressBlockComponent;