import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import styles from './styles';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/icons/Menu";
import clsx from 'clsx';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles(styles);

interface INavbar {
  color?: "primary" | "info" | "success" | "warning" | "danger",
  // rtlActive?: boolean,
  handleDrawerToggle?(): any,
  // routes
  links?: React.ReactNode,
}

const CustomToolbar: React.FC<INavbar> = (props) => {
  const classes = useStyles();

  const {
    color,
    handleDrawerToggle,
    links,
    // children, 
  } = props;

  let appBarClasses = "";

  if (color !== undefined) {
    appBarClasses += clsx(classes[color], color);
  }


  return (
    <AppBar className={classes.appBar + " " + appBarClasses} position="sticky" color="default" >
      <Toolbar>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Breadcrumbs aria-label="breadcrumb" style={{color:'white'}}>
            <Link color="inherit" href="/dashboard" >
              Dashboard
            </Link>
            <Typography >Agreement</Typography>
          </Breadcrumbs>
          {/* <Button color="transparent" href="#" className={classes.title}>
            OIE
          </Button> */}
        </div>
        <Hidden smDown implementation="css">
          {links}
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

export default CustomToolbar;


