import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import jwt_decode from "jwt-decode";

interface IPrivateRoute {
  component: React.ComponentType
}

interface IToken {
  name: string,
  exp: number,
  iat: number
}

const PrivateRoute: React.FC<IPrivateRoute> = (props) => {

  const {component, ...rest} = props;
  const Component = props.component;
  let valid = false;

  const localData = localStorage.getItem('user');
  if(localData){
    const user = JSON.parse(localData);
    if(user.token){
      let decoded = jwt_decode<IToken>(user.token);
      if(decoded?.exp){
        if ((Date.now() / 1000) >= decoded.exp) {
          valid = false;
        }
        else{
          valid = true;
        }
      }
    }
  }

  return (
    <Route {...rest} >
      {valid? 
        <Component />
        :
        <Redirect to="/login" />
      }
    </Route>
  )
}

export default PrivateRoute;