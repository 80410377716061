"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcNext = exports.calcRecurrence = exports.parseRecurrence = void 0;
var date_fns_1 = require("date-fns");
var daily = function (args) {
    var startDate = args.startDate, expirationDate = args.expirationDate, every = args.every, skipWeekends = args.skipWeekends;
    var interval = {
        start: startDate,
        end: expirationDate,
    };
    var daysOfInterval = date_fns_1.eachDayOfInterval(interval);
    var filteredDays = [];
    for (var i = 0; i < daysOfInterval.length; i += every) {
        if (skipWeekends && date_fns_1.isWeekend(daysOfInterval[i])) {
            if (date_fns_1.isSaturday(daysOfInterval[i])) {
                filteredDays.push(date_fns_1.subDays(daysOfInterval[i], 1));
            }
            else if (date_fns_1.isSunday(daysOfInterval[i])) {
                filteredDays.push(date_fns_1.subDays(daysOfInterval[i], 2));
            }
        }
        else {
            filteredDays.push(daysOfInterval[i]);
        }
    }
    return filteredDays;
};
var weekly = function (args) {
    var startDate = args.startDate, expirationDate = args.expirationDate, every = args.every, onDaysOfTheWeek = args.onDaysOfTheWeek;
    var interval = {
        start: startDate,
        end: expirationDate,
    };
    var weeksOfInterval = date_fns_1.eachWeekOfInterval(interval);
    var filteredWeeks = [];
    for (var i = 0; i < weeksOfInterval.length; i += every) {
        if (onDaysOfTheWeek) {
            var keys = Object.keys(onDaysOfTheWeek);
            for (var j = 0; j < keys.length; j += 1) {
                if (onDaysOfTheWeek[keys[j]]) {
                    var dayNumber = 0;
                    if (keys[j] === 'monday') {
                        dayNumber = 1;
                    }
                    if (keys[j] === 'tuesday') {
                        dayNumber = 2;
                    }
                    if (keys[j] === 'wednesday') {
                        dayNumber = 3;
                    }
                    if (keys[j] === 'thursday') {
                        dayNumber = 4;
                    }
                    if (keys[j] === 'friday') {
                        dayNumber = 5;
                    }
                    if (keys[j] === 'saturday') {
                        dayNumber = 6;
                    }
                    filteredWeeks.push(date_fns_1.setDay(weeksOfInterval[i], dayNumber));
                }
            }
        }
        else {
            filteredWeeks.push(weeksOfInterval[i]);
        }
    }
    return filteredWeeks;
};
var monthly = function (args) {
    var startDate = args.startDate, expirationDate = args.expirationDate, every = args.every, onDayOfMonth = args.onDayOfMonth, skipWeekends = args.skipWeekends;
    var interval = {
        start: startDate,
        end: expirationDate,
    };
    var monthsOfInterval = date_fns_1.eachMonthOfInterval(interval);
    var filteredMonths = [];
    for (var i = 0; i < monthsOfInterval.length; i += every) {
        if (onDayOfMonth) {
            var dayOfMonth = date_fns_1.setDate(monthsOfInterval[i], onDayOfMonth);
            if (skipWeekends && date_fns_1.isWeekend(dayOfMonth)) {
                if (date_fns_1.isSaturday(dayOfMonth)) {
                    filteredMonths.push(date_fns_1.subDays(dayOfMonth, 1));
                }
                else if (date_fns_1.isSunday(dayOfMonth)) {
                    filteredMonths.push(date_fns_1.subDays(dayOfMonth, 2));
                }
            }
            else {
                filteredMonths.push(dayOfMonth);
            }
        }
        else {
            filteredMonths.push(monthsOfInterval[i]);
        }
    }
    return filteredMonths;
};
function parseRecurrence(args) {
    var parsedArgs = __assign({}, args);
    if (typeof (args === null || args === void 0 ? void 0 : args.startDate) === 'string') {
        parsedArgs.startDate = new Date(args.startDate);
    }
    if (typeof (args === null || args === void 0 ? void 0 : args.expirationDate) === 'string') {
        parsedArgs.expirationDate = new Date(args.expirationDate);
    }
    if (typeof (args === null || args === void 0 ? void 0 : args.startTime) === 'string') {
        parsedArgs.startTime = new Date(args.startTime);
    }
    if (typeof (args === null || args === void 0 ? void 0 : args.endTime) === 'string') {
        parsedArgs.endTime = new Date(args.endTime);
    }
    if (typeof (args === null || args === void 0 ? void 0 : args.every) === 'string') {
        parsedArgs.every = parseInt(args.every);
    }
    return parsedArgs;
}
exports.parseRecurrence = parseRecurrence;
function calcRecurrence(args) {
    var parsedArgs = parseRecurrence(args);
    if (!(parsedArgs.startDate instanceof Date)) {
        return [];
    }
    if (!(parsedArgs.expirationDate instanceof Date)) {
        return [];
    }
    if (!Number.isInteger(parsedArgs.every)) {
        return [];
    }
    var result = Array();
    try {
        switch (parsedArgs.repeat) {
            case 'daily':
                result = daily(parsedArgs);
                break;
            case 'weekly':
                result = weekly(parsedArgs);
                break;
            case 'monthly':
                result = monthly(parsedArgs);
                break;
            default:
                return [];
        }
    }
    catch (err) {
        return [];
    }
    return result;
}
exports.calcRecurrence = calcRecurrence;
function calcNext(args) {
    var now = new Date();
    var recurrence = calcRecurrence(args);
    var result;
    for (var i = 0; i < recurrence.length; i += 1) {
        if (recurrence[i] >= now) {
            result = recurrence[i];
            break;
        }
    }
    return result;
}
exports.calcNext = calcNext;
