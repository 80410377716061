import React from 'react';
import { ToolbarProps } from 'react-big-calendar';
// import Paper from '@material-ui/core/Paper';
// import format from 'date-fns/format';
// import parse from 'date-fns/parse';
// import startOfWeek from 'date-fns/startOfWeek';
// import getDay from 'date-fns/getDay';
import Button from 'components/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const Toolbar: React.FC<ToolbarProps> = (props) => {

  const { label, onNavigate, view, onView} = props;

  return (
    <Grid 
      container 
      // spacing={2} 
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      
      <Grid item xs={4}>
        <Button 
          color="rose"
          round
          size="sm"
          onClick={() => {
            onNavigate('TODAY')
          }}
        >
          Today
        </Button>

        <Button 
          color="rose"
          round
          size="sm"
          onClick={() => {
            onNavigate('PREV')
          }}
        >
          Back
        </Button>

        <Button 
          color="rose"
          round
          size="sm"
          onClick={() => {
            onNavigate('NEXT')
          }}
        >
          Next
        </Button>
      </Grid>
      
      <Grid item xs={4}>
          
        <Typography style={{textAlign: 'center'}}>{label}</Typography>
      </Grid>

    <Grid item xs={4}>
      <div style={{float: 'right'}}>
        
        <Button 
          color={view === 'month' ? 'success' : 'primary'}
          round
          size="sm" 
          onClick={() => {
            onView('month')
        }}
        >
          Month
        </Button>

        <Button 
          color={view === 'week' ? 'success' : 'primary'}
          round
          size="sm" 
          onClick={() => {
            onView('week')
        }}
        >
          Week
        </Button>

        <Button 
          color={view === 'day' ? 'success' : 'primary'}
          round
          size="sm" 
          onClick={() => {
            onView('day')
        }}
        >
          Day
        </Button>

        <Button 
          color={view === 'agenda' ? 'success' : 'primary'}
          round
          size="sm" 
          onClick={() => {
            onView('agenda')
        }}
        >
          Agenda
        </Button>
      </div>
    </Grid>

    
    </Grid>

  )
}

export default Toolbar;