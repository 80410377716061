import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import styles from './styles';

const useStyles = makeStyles(styles);

const AnimatedSuccessICons: React.FC = (porps) => {

  const classes = useStyles();

  return (
    <>
    <div className={classes.sa}>
      <div className={classes.saSuccess}>
        <div className={classes.saSuccessTip}></div>
        <div className={classes.saSuccessLong}></div>
        <div className={classes.saSuccessPlaceholder}></div>
        <div className={classes.saSuccessFix}></div>
      </div>
    </div>
    </>
  )
}

export default AnimatedSuccessICons;
