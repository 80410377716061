import React, { useEffect, useCallback } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import styles from './styles';
import Swal from 'components/Swal2';
import { useHistory } from "react-router-dom";
import Header from 'components/Header';
import Api from 'Services/api';

const useStyles = makeStyles(styles);

const ConfirmEmail: React.FC = () => {
  const classes = useStyles();
  let history = useHistory();
  
  async function confirmEmailx(){
    try{
      let url = window.location.href.split('/');
      const token = url[url.length -1].toString();
      const response = await Api.get(`/confirm-email/${token}`);
      const confirm = await Swal.fire({
        title: 'Success!',
        text: JSON.stringify(response?.data),
        icon: 'success',
        confirmButtonText: 'OK'
      })
      
      if(confirm.isConfirmed){
        history.push('/login');
      }
    }
    catch(err){
      console.log(err);
    }
  }

  const memorizedCallback = useCallback(confirmEmailx, []);

  useEffect( () => {
    memorizedCallback();
  }, [memorizedCallback]);


  return (
    <>
    
    <div
      className={classes.pageHeader}
      style={{
        backgroundImage: "url(/assets/img/bg20.jpg)",
        // backgroundImage: "url(https://source.unsplash.com/random?cosmos)",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    >

  <div className={classes.animatedBackground}>
  <div className={classes.container}>
    <Header 
      color="transparent"
      absolute
      brand={<img 
        src="/assets/img/logo.png" 
        alt="logo" 
        height="45px"
        style={{
          // filter: 'drop-shadow(5px 5px 5px #1fc1d5)'
        }}
      />}
    />
    
    
    </div>
    {/* <ParticlesBg type="random" bg={true} /> */}
    
  </div>
  </div>
  
  </>
  )
}

export default ConfirmEmail;
