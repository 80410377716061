import React, {useContext} from 'react';
// import Grid from '@material-ui/core/Grid';
import Button from 'components/Button';
// import {Dialog} from 'components/Dialog';

import {DialogContext} from 'components/Dialog';
import AnimatedSucessIcon from 'components/Dialog/AnimatedSuccessIcon';

const Dashboard: React.FC = () => {

  const {fire} = useContext(DialogContext);

  // const xs = 4;
  return (
    <>
      <Button
        color="rose"
        onClick={async () => {
          console.log('click');
          const data = await fire({title: ""});
          alert(JSON.stringify(data));
        }}
      >
        Launch Dialog
      </Button>
      <AnimatedSucessIcon/>
    </>
  )
}

export default Dashboard;