import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {calcRecurrence, parseRecurrence} from '@dsrcloud/utils';
import { Event } from 'react-big-calendar';
import Calendar from 'components/Calendar';
import { DatePicker, TimePicker } from "@material-ui/pickers";

import {
  TextField,
  // Switch,
  // FormControlLabel,
  MenuItem,
  Typography,
  // Checkbox
} from '@material-ui/core';
import Switch from 'components/FormInputs/Switch';
// import addDays from 'date-fns/addDays';
import {
  addMinutes,
  // addHours,
  getMinutes,
  getHours,
  setHours,
  setMinutes,
  // parseISO
} from 'date-fns';

import IconCard from 'components/IconCard';
import Checkbox from 'components/FormInputs/CheckBox';

import { useForm, Controller } from "react-hook-form";
import NumberFormat from 'react-number-format';
import {SiGooglecalendar} from 'react-icons/si';

import { Recurrence } from "@dsrcloud/types";

// import MoneyInput from 'components/FormInputs/MoneyInput';

// import { makeStyles } from "@material-ui/core/styles";
// import styles from './styles';
// const useStyles = makeStyles(styles);

interface ISchedule {
  readOnly?: boolean,
  defaultValues?: any,
  onSubmit?: Function
}


interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}


const Schedule: React.FC<ISchedule> = (props) => {
  // const classes = useStyles();

  const {
    defaultValues,
    readOnly,
    onSubmit,
    // ...rest
  } = props;

  
  // console.log('defaultvalues');
  // console.log(defaultValues);

  const { register, handleSubmit, formState, reset, control, watch } = useForm({
    mode: 'onChange',
    // defaultValues: parseDefaultValues(defaultValues),
    defaultValues
  });

  const repeat = watch('schedule.recurrence.repeat');
  const recurrence = watch('schedule.recurrence');
  // const [calendarEvents, setCalendarEvents] = useState<Array<Event>>([]);


  const datesToCalendarEvents = (dates: Date[], recurrence: Recurrence) => {

    let events: Event[] = [];

    if(Array.isArray(dates)){
      events = dates.map( (eventDate) => {
        console.log('aqui');
        console.log(eventDate);
        console.log(recurrence.startTime)
        console.log(recurrence.endTime)

        let start = eventDate;
        if(recurrence.startTime){
          const minutes = getMinutes(recurrence.startTime);
          const hours = getHours(recurrence.startTime);
          start = setHours(start, hours);
          start = setMinutes(start, minutes);
          console.log(`minutes ${hours} - ${minutes} `)
        }

        let end = eventDate;
        if(recurrence.endTime){
          const minutes = getMinutes(recurrence.endTime);
          const hours = getHours(recurrence.endTime);
          end = setHours(end, hours);
          end = setMinutes(end, minutes);
          console.log(`minutes ${hours} - ${minutes} `)
        }else {
          end = addMinutes(end, 30)
        }

        return {
          title: 'Invoice',
          start: start,
          end: end,
          allDay: false
        }
      });
      // console.log('events ');
      // console.log(events);
    }
    // else{
    //   console.log('not an array');
    // }
    return events;
  }

  const [loading, setLoading] = useState(false);

  
  const submit = async (data: any) => {
    if (onSubmit) {
      setLoading(true);
      try {
        console.log(JSON.stringify(data, null, 2));
        // const recurrence = cleanRecurrence(data.billingFeatures?.recurrence);
        // const cleanFormData = {billingFeatures: {...data.billingFeatures, recurrence }};
        // const response = await onSubmit(cleanFormData);
        const response = await onSubmit(data);
        reset(response);
      }
      catch(error){
        alert('error');
      }
      finally{
        setLoading(false);
      }
    }
  }

  const fieldSize = 'small';
  return (
    <IconCard 
      icon={<SiGooglecalendar />}
      iconColor="success"
      title="Schedule"
      loading={loading}
      isDirty={formState.isDirty}
      resetFunction={reset}
      saveFunction={ () => handleSubmit(submit)() }
    >
      <form onSubmit={handleSubmit(submit)}>
        <Grid container spacing={2}>

        <Grid item xs={12} sm={5} >
          <Switch 
            label="Send Service Request Confirmation"
            name="schedule.sendServiceRequestConfirmation"
            control={control}
            readOnly={readOnly}
            defaultValue={false}
          />
          </Grid>


          {/* AMOUNT */}
          {/* USING react-number-format + react-hook-form */}
          {/* https://spectrum.chat/react-hook-form/help/controller-and-numberformat~198e2963-02d5-4594-870d-2980f8626724 */}

          {/* <Grid item xs={12} sm={7}>
            <MoneyInput 
              control={control}
              name="billingFeatures.amount"
              readOnly={readOnly}
              label="Amount"
              defaultValue={0}
            />
          </Grid> */}
          
          <Grid item xs={12} sm={12}>
            <Typography>Recurrence:</Typography>
          </Grid>
          
          <Grid container item spacing={2}>

            {/* START DATE */}
          <Grid item xs={12} sm={3}>
            <Controller
              as={DatePicker}
              control={control}
              value={null}
              defaultValue={null}
              renderInput={(props: any) => 
                <TextField 
                  {...props} 
                  variant="outlined" 
                  label="Start Date" 
                  fullWidth 
                  size={fieldSize}
                  // error={errors?.adminFeatures?.startDate ? true : false}
                  // helperText={errors?.adminFeatures?.startDate?.message}
                />}
              onChange={(date: any)=> date} //TODO change this
              name="schedule.recurrence.startDate"
              readOnly={readOnly}
            />
          </Grid>
          
          {/* EXPIRATION DATE */}
          <Grid item xs={12} sm={3}>
            <Controller
              as={DatePicker}
              control={control}
              value={null}
              defaultValue={null}
              onChange={(date: any)=> date} //TODO change this
              renderInput={(props: any) => 
                <TextField 
                  {...props} 
                  variant="outlined" 
                  label="Expiration Date" 
                  fullWidth 
                  size={fieldSize}
                  // error={errors?.adminFeatures?.expirationDate ? true : false}
                  // helperText={errors?.adminFeatures?.expirationDate?.message}
                />}
              name="schedule.recurrence.expirationDate"
              readOnly={readOnly}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Controller
              as={TimePicker}
              control={control}
              value={null}
              defaultValue={null}
              onChange={(data: any) => data}
              renderInput={(props: any) => 
                <TextField 
                  {...props} 
                  variant="outlined" 
                  label="Start Time" 
                  fullWidth 
                  size={fieldSize}
                  // error={errors?.squedule?.startDate ? true : false}
                  // helperText={errors?.squedule?.startDate?.message}
                />}
              name="schedule.recurrence.startTime"
              readOnly={readOnly}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Controller
              as={TimePicker}
              control={control}
              value={null}
              defaultValue={null}
              onChange={(data: any) => data}
              renderInput={(props: any) => 
                <TextField 
                  {...props} 
                  variant="outlined" 
                  label="End Time" 
                  fullWidth 
                  size={fieldSize}
                  // error={errors?.squedule?.startDate ? true : false}
                  // helperText={errors?.squedule?.startDate?.message}
                />}
              name="schedule.recurrence.endTime"
              readOnly={readOnly}
            />
          </Grid>


            <Grid item xs={12} sm={3}>
              <Controller 
                  select
                  label="Repeat"
                  control={control}
                  variant="outlined"
                  InputProps={{ readOnly }}
                  name="schedule.recurrence.repeat"
                  fullWidth
                  size={fieldSize}
                  defaultValue=""
                  as={
                    <TextField>
                      <MenuItem value="daily">Daily</MenuItem>
                      <MenuItem value="weekly">Weekly</MenuItem>
                      <MenuItem value="monthly">Monthly</MenuItem>
                    </TextField>
                  }
              />
            </Grid>


            <Grid item xs={12} sm={2} >
              <TextField 
                variant="outlined"
                fullWidth
                label={`Every`}
                type='number'
                size={fieldSize}
                inputRef={register()}
                name={`schedule.recurrence.every`}
                InputProps={{ readOnly,inputProps: { min: 1}  }}
                // defaultValue={item.propertyName}
                // defaultValue=""
                defaultValue={1}
              />
            </Grid>

            
            {repeat === 'monthly' && 
            <Grid item xs={12} sm={2} >
              <TextField 
                variant="outlined"
                fullWidth
                label={`Day Of Month`}
                type='number'
                size={fieldSize}
                inputRef={register()}
                name={`schedule.recurrence.onDayOfMonth`}
                InputProps={{ readOnly, inputProps: { min: 1, max:31} }}
                // defaultValue={item.propertyName}
                defaultValue={1}
              />
            </Grid>
            }

            {repeat === 'weekly' && 
            <Grid item xs={12} sm={7} >

              <Checkbox 
                control={control}
                label="Sunday"
                name={`schedule.recurrence.onDaysOfTheWeek.sunday`}
                defaultValue={false}
              />

              <Checkbox 
                control={control}
                label="Monday"
                name={`schedule.recurrence.onDaysOfTheWeek.monday`}
                defaultValue={false}
              />
 
              <Checkbox 
                control={control}
                label="Tuesday"
                name={`schedule.recurrence.onDaysOfTheWeek.tuesday`}
                defaultValue={false}
              />

              <Checkbox 
                control={control}
                label="Wednesday"
                name={`schedule.recurrence.onDaysOfTheWeek.wednesday`}
                defaultValue={false}
              />

              <Checkbox 
                control={control}
                label="Thursday"
                name={`schedule.recurrence.onDaysOfTheWeek.thursday`}
                defaultValue={false}
              />

              <Checkbox 
                control={control}
                label="Friday"
                name={`schedule.recurrence.onDaysOfTheWeek.friday`}
                defaultValue={false}
              />

              <Checkbox 
                control={control}
                label="Saturday"
                name={`schedule.recurrence.onDaysOfTheWeek.saturday`}
                defaultValue={false}
              />

              
            </Grid>
            }

            {repeat !== 'weekly' && 
            <Grid item xs={12} sm={4}>
              <Switch 
                control={control}
                label="Skip Weekends"
                name="schedule.recurrence.skipWeekends"
                defaultValue={false}
                readOnly={readOnly}
              />

              
            </Grid>
            }


          <Grid item xs={12} sm={3}>
            <Controller 
                select
                label="Period"
                control={control}
                variant="outlined"
                InputProps={{ readOnly }}
                name="schedule.period"
                // error={errors?.billingFeatures?.terms ? true : false}
                // helperText={errors?.billingFeatures?.terms?.message}
                fullWidth
                size={fieldSize}
                defaultValue=""
                as={
                  <TextField>
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="Morning">Morning</MenuItem>
                    <MenuItem value="Afternoon">Afternoon</MenuItem>
                    <MenuItem value="Night">Night</MenuItem>
                    
                  </TextField>
                }
            />
          </Grid>




          <Grid item xs={12} sm={12}>
            <Calendar
              events={datesToCalendarEvents(calcRecurrence(recurrence), parseRecurrence(recurrence))}
            />
          </Grid>
 

          
        </Grid>
        
         

        </Grid>
   
      </form>

    </IconCard>
  )
}

export default Schedule;