import { createStyles, Theme } from '@material-ui/core/styles';
import { hexToRgb } from 'utils/themeUtils';

const defaultFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  // fontWeight: "300",
  lineHeight: "1.5em"
};

const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
};


const styles = ( {gradientPalette, drawerWidth, breakpoints, palette }: Theme) => createStyles({
  userList: {
    marginTop: -10,
  },
  xx: {
    backgroundColor: 'gray',
    border: 0,
    padding: 0
  },
  user: {
    position: "relative",
    color: gradientPalette.grayColor[4],
    fontSize: '14px',
    // paddingLeft: "5px",
    // paddingRight: "5px",
    // zIndex: "4",
    "z-index": "4",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "0",

      height: "1px",
      right: "15px",
      width: "calc(100% - 30px)",
      backgroundColor: "rgba(" + hexToRgb(gradientPalette.grayColor[6]) + ", 0.3)"
    }
  },
  drawerPaper: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    // zIndex: "1",
    "z-index": "1",
    boxShadow: "0 10px 30px -12px rgba(" + hexToRgb(gradientPalette.blackColor) + ", 0.42), 0 4px 25px 0px rgba(" + hexToRgb(gradientPalette.blackColor) + ", 0.12), 0 8px 10px -5px rgba(" + hexToRgb(gradientPalette.blackColor) + ", 0.2)",
    // width: drawerWidth,
    [breakpoints.up("md")]: {
      width: drawerWidth,
      position: "fixed",
      height: "100%"
    },
    [breakpoints.down("sm")]: {
      // width: drawerWidth,
      boxShadow: "0 10px 30px -12px rgba(" + hexToRgb(gradientPalette.blackColor) + ", 0.42), 0 4px 25px 0px rgba(" + hexToRgb(gradientPalette.blackColor) + ", 0.12), 0 8px 10px -5px rgba(" + hexToRgb(gradientPalette.blackColor) + ", 0.2)",
      position: "fixed",
      display: "block",
      top: "0",
      height: "100vh",
      right: "0",
      left: "auto",
      zIndex: "1032",
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition
    }
  },
  drawerPaperRTL: {
    [breakpoints.up("md")]: {
      left: "auto !important",
      right: "0 !important"
    },
    [breakpoints.down("sm")]: {
      left: "0  !important",
      right: "auto !important"
    }
  },
  logo: {
    position: "relative",
    padding: "15px 15px",
    // zIndex: "4",
    "z-index": "4",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "0",

      height: "1px",
      right: "15px",
      width: "calc(100% - 30px)",
      backgroundColor: "rgba(" + hexToRgb(gradientPalette.grayColor[6]) + ", 0.3)"
    }
  },
  logoLink: {
    ...defaultFont,
    textTransform: "uppercase",
    // padding: "5px 0",
    display: "block",
    fontSize: "18px",
    textAlign: "left",
    // fontWeight: "400",
    lineHeight: "30px",
    textDecoration: "none",
    backgroundColor: "transparent",
    "&,&:hover": {
      color: gradientPalette.whiteColor
    }
  },
  logoLinkRTL: {
    textAlign: "right"
  },
  logoImage: {
    width: "30px",
    height: "25px",
    display: "inline-block",
    // maxHeight: "30px",
    // marginLeft: "10px",
    // marginRight: "15px"
  },
  img: {
    // width: "35px",
    maxWidth: "calc(100% - 30px)",
    // top: "22px",
    top: "10px",
    position: "absolute",
    verticalAlign: "middle",
    border: "0"
  },
  background: {
    position: "absolute",
    // zIndex: "1",
    "z-index": "1",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      background: gradientPalette.blackColor,
      opacity: ".8"
    }
  },
  list: {
    marginTop: "20px",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none",
    position: "unset"
  },
  item: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    "&:hover,&:focus,&:visited,&": {
      color: gradientPalette.whiteColor
    }
  },
  itemLink: {
    width: "auto",
    transition: "all 300ms linear",
    margin: "10px 15px 0",
    borderRadius: "3px",
    position: "relative",
    // display: "block",
    padding: "10px 15px",
    backgroundColor: "transparent",
    ...defaultFont
  },
  itemIcon: {
    width: "24px",
    height: "30px",
    fontSize: "24px",
    lineHeight: "30px",
    float: "left",
    marginRight: "15px",
    textAlign: "center",
    verticalAlign: "middle",
    color: "rgba(" + hexToRgb(gradientPalette.whiteColor) + ", 0.8)"
  },
  itemIconRTL: {
    marginRight: "3px",
    marginLeft: "15px",
    float: "right"
  },
  itemText: {
    ...defaultFont,
    margin: "0",
    lineHeight: "30px",
    fontSize: "14px",
    color: gradientPalette.whiteColor
  },
  itemTextRTL: {
    textAlign: "right"
  },
  whiteFont: {
    color: gradientPalette.whiteColor
  },
  purple: {
    backgroundColor: gradientPalette.primaryColor[0],
    boxShadow: "0 4px 20px 0 rgba(" + hexToRgb(gradientPalette.blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(gradientPalette.primaryColor[0]) + ",.4)",
    "&:hover,&:focus": {
      backgroundColor: gradientPalette.primaryColor[0],
      boxShadow: "0 4px 20px 0 rgba(" + hexToRgb(gradientPalette.blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(gradientPalette.primaryColor[0]) + ",.4)"
    }
  },
  blue: {
    backgroundColor: gradientPalette.infoColor[0],
    boxShadow:
      "0 12px 20px -10px rgba(" +
      hexToRgb(gradientPalette.infoColor[0]) +
      ",.28), 0 4px 20px 0 rgba(" +
      hexToRgb(gradientPalette.blackColor) +
      ",.12), 0 7px 8px -5px rgba(" +
      hexToRgb(gradientPalette.infoColor[0]) +
      ",.2)",
    "&:hover,&:focus": {
      backgroundColor: gradientPalette.infoColor[0],
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(gradientPalette.infoColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(gradientPalette.blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(gradientPalette.infoColor[0]) +
        ",.2)"
    }
  },
  green: {
    backgroundColor: gradientPalette.successColor[0],
    boxShadow:
      "0 12px 20px -10px rgba(" +
      hexToRgb(gradientPalette.successColor[0]) +
      ",.28), 0 4px 20px 0 rgba(" +
      hexToRgb(gradientPalette.blackColor) +
      ",.12), 0 7px 8px -5px rgba(" +
      hexToRgb(gradientPalette.successColor[0]) +
      ",.2)",
    "&:hover,&:focus": {
      backgroundColor: gradientPalette.successColor[0],
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(gradientPalette.successColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(gradientPalette.blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(gradientPalette.successColor[0]) +
        ",.2)"
    }
  },
  orange: {
    backgroundColor: gradientPalette.warningColor[0],
    boxShadow:
      "0 12px 20px -10px rgba(" +
      hexToRgb(gradientPalette.warningColor[0]) +
      ",.28), 0 4px 20px 0 rgba(" +
      hexToRgb(gradientPalette.blackColor) +
      ",.12), 0 7px 8px -5px rgba(" +
      hexToRgb(gradientPalette.warningColor[0]) +
      ",.2)",
    "&:hover,&:focus": {
      backgroundColor: gradientPalette.warningColor[0],
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(gradientPalette.warningColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(gradientPalette.blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(gradientPalette.warningColor[0]) +
        ",.2)"
    }
  },
  red: {
    backgroundColor: gradientPalette.dangerColor[0],
    boxShadow:
      "0 12px 20px -10px rgba(" +
      hexToRgb(gradientPalette.dangerColor[0]) +
      ",.28), 0 4px 20px 0 rgba(" +
      hexToRgb(gradientPalette.blackColor) +
      ",.12), 0 7px 8px -5px rgba(" +
      hexToRgb(gradientPalette.dangerColor[0]) +
      ",.2)",
    "&:hover,&:focus": {
      backgroundColor: gradientPalette.dangerColor[0],
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(gradientPalette.dangerColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(gradientPalette.blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(gradientPalette.dangerColor[0]) +
        ",.2)"
    }
  },
  sidebarWrapper: {
    position: "relative",
    height: "calc(100vh - 75px)",
    overflow: "auto",
    // width: "260px",
    width: drawerWidth,
    // zIndex: "4",
    "z-index": "4",
    overflowScrolling: "touch",
    // backgroundColor: 'green',
    // padding: 0,
    // margin: 0
  },
  activePro: {
    [breakpoints.up("md")]: {
      position: "absolute",
      width: "100%",
      bottom: "13px"
    }
  }
});

export default styles;
