import React, { createContext, useState, useRef } from "react";

import CustomDialog from './Dialog';

interface IDialogOptions {
  icon?: 'error' | 'warning' | 'success',
  title?: string,
  message?: string,
  confirmButtonText?: string,
  cancelButtonText?: string,
}

interface IDialogResponse {
  isConfirmed: boolean
}

interface IInitialState {
  fire: (options: IDialogOptions) => Promise<IDialogResponse>
}

// const initialState = {
//   fire: (options: IDialogOptions) => {},
// }

const DialogContext = createContext<IInitialState>({
  fire: (options) => {
    return new Promise((resolve, reject) => {
      resolve({isConfirmed: false});
    });
  }
});

const DialogContextProvider: React.FC = (props) => {

  const { children } = props;
  const [isOpen, openDialog] = useState(false);

  const awaitingPromiseRef = useRef<{
    resolve: () => void;
    reject: () => void;
  }>();

  function handleDialog(){
    openDialog(!isOpen);
  }

  const fire = ( options : IDialogOptions ) : Promise<IDialogResponse> => {
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = {resolve, reject};
      openDialog(true);
    });
  }

  // const handleSubmit = (response: IDialogResponse) => {
  //   if (awaitingPromiseRef.current) {
  //     awaitingPromiseRef.current.resolve();
  //   }
  // }


  return (
    <DialogContext.Provider value={{fire}} >
      <CustomDialog open={isOpen} onClose={handleDialog} />
      {children}
    </DialogContext.Provider>
  )
}

export { DialogContext, DialogContextProvider };
