import React from 'react';
// import Button from "@material-ui/core/Button";
// import { ButtonProps } from '@material-ui/core/Button';
// import { makeStyles } from "@material-ui/core/styles";
// import clsx from 'clsx';
// import styles from './styles';

import {
  FormControlLabel,
  Checkbox
} from '@material-ui/core';

import {Control, Controller} from 'react-hook-form';

// const useStyles = makeStyles(styles);


interface ICheckBox {
  name: string;
  control: Control;
  defaultValue?: boolean;
  label?: string;
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
  readOnly?: boolean;
}

const CustomCheckbox: React.FC<ICheckBox> = (props) => {
  // const classes = useStyles();

  const {
    name, 
    control,
    label,
    labelPlacement,
    readOnly,
    defaultValue,
    // ...rest
  } = props;

  return (
    <FormControlLabel
      labelPlacement={labelPlacement}
      control={
        <Controller 
          control={control}
          name={name}
          type="checkbox"
          defaultValue={defaultValue ? defaultValue : false}
          render={ ({onChange, onBlur, value,  ...rest}) => {
            return(
            <Checkbox
              checked={value}
              onChange={ (event) => {onChange(event.target.checked);}}
              onBlur={onBlur}
              inputProps={{ 'aria-label': 'primary checkbox' }}
              disabled={readOnly}
            />
          )}
          }
        />
      }
      label={label}
    />
  )
}

export default CustomCheckbox;