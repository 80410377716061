import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';


import {
  TextField,
} from '@material-ui/core';


import IconCard from 'components/IconCard';

import { useForm } from "react-hook-form";

import {AiOutlineFileSearch} from 'react-icons/ai';

// import { makeStyles } from "@material-ui/core/styles";
// import styles from './styles';
// const useStyles = makeStyles(styles);

interface IIdentification {
  readOnly?: boolean,
  defaultValues?: any,
  onSubmit?: Function
}

const Identification: React.FC<IIdentification> = (props) => {
  // const classes = useStyles();

  const {
    defaultValues,
    readOnly,
    onSubmit,
    // ...rest
  } = props;


  const { register, handleSubmit, errors, formState, reset } = useForm({
    mode: 'onChange',
    defaultValues,
  });


  const [loading, setLoading] = useState(false);

  const submit = async (data: any) => {
    setLoading(true);
    if (onSubmit) {
      try {
        const response = await onSubmit(data);
        reset(response);
      }
      catch(error){
        alert('error');
      }
      finally{
        setLoading(false);
      }
    }
  }

  const fieldSize = 'small';
  return (
    <IconCard 
      icon={<AiOutlineFileSearch />}
      iconColor="info"
      title="Identification"
      readOnly={readOnly}
      isDirty={formState.isDirty}
      resetFunction={reset}
      saveFunction={ () => handleSubmit(submit)() }
      loading={loading}
    >
      <form onSubmit={handleSubmit(submit)}>
        <Grid container spacing={2}>

          <Grid item xs={12} sm={2}>
            <TextField 
              variant="outlined"
              fullWidth
              label="Number"
              size={fieldSize}
              inputRef={register}
              name="identification.number"
              error={errors?.identification?.number ? true : false}
              helperText={errors?.identification?.number?.message}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <TextField 
              variant="outlined"
              fullWidth
              label="Title"
              size={fieldSize}
              inputRef={register}
              name="identification.title"
              error={errors?.identification?.title ? true : false}
              helperText={errors?.identification?.title?.message}
              // disabled={disabled}
              InputProps={{ readOnly }}
            />
          </Grid>

          {/* { !readOnly && (
            <Grid container spacing={2} justify="flex-end" alignItems="flex-end">
              { formState.isDirt && (
                <Grid item>
                  <Button 
                    disabled={formState.isSubmitting}
                    color="danger" 
                    onClick={() => {reset()}}
                  >
                    Reset
                  </Button>
                </Grid>
              )}

              { formState.isDirt && (
                <Grid item>
                  <Button 
                    color="success" 
                    disabled={formState.isSubmitting}
                    onClick={() => {
                      handleSubmit(submit)();
                    }}
                  >
                    Update
                  </Button>
                </Grid>
              )}

              <Grid item sm={12} >
                { formState.isSubmitting && (<LinearProgress />)}
              </Grid>
            </Grid>
          )} */}

        </Grid>
        {/* <pre>{JSON.stringify(formState, null, 2)}</pre> */}
      </form>
      {/* { formState.isSubmitting && (<LinearProgress />)} */}
    </IconCard>
  )
}

export default Identification;