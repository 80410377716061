import React, {useContext, useEffect} from 'react';
import 'fontsource-roboto';
import Theme, {DarkTheme} from './Theme';
import {MainContext} from 'context/MainContext';
import { ThemeProvider } from '@material-ui/styles';
import { ThemeProvider as StyledThemeProvider} from 'styled-components';
import { SnackbarProvider } from 'notistack';
import LoginScreen from './screens/login';
import RegisterScreen from './screens/register';
import LayoutScreen from './screens/layout';
import ConfirmationScreen from './screens/confirmEmail';
import CssBaseline from '@material-ui/core/CssBaseline';

import ScrollToTop from 'utils/ScrollToTop';
import PrivateRoute from 'routes/PrivateRoute';
import {DialogContextProvider} from 'components/Dialog';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { LocalizationProvider } from '@material-ui/pickers';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";


function App() {

  const {theme, setTheme} = useContext(MainContext);
  
  // change theme acording system color theme
  useEffect(() => {
    const isDark = window.matchMedia('(prefers-color-scheme: dark)');
    setTheme(!isDark ? 'dark' : 'light')
    isDark.addEventListener('change', e => {
      const newColorScheme = e.matches ? "dark" : "light";
      setTheme(newColorScheme);
    });
  }, [setTheme]);

  return (
    <>
      <CssBaseline/>
      <ThemeProvider theme={ theme !== 'dark' ? Theme : DarkTheme}>
        <StyledThemeProvider theme={ theme !== 'dark' ? Theme : DarkTheme}>
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <SnackbarProvider 
              maxSnack={3}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              autoHideDuration={1700}
            >
              <DialogContextProvider>
                <Router>
                  <ScrollToTop/>
                  <Switch>
                    <Route path="/login" strict exact><LoginScreen /></Route>
                    <Route path="/register" strict exact><RegisterScreen /></Route>
                    <Route path="/confirm-email" ><ConfirmationScreen /></Route>
                    <PrivateRoute component={LayoutScreen} />
                  </Switch>
                </Router>
              </DialogContextProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </StyledThemeProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
