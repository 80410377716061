import React, {useEffect, useState} from 'react';
import { Calendar, dateFnsLocalizer, ToolbarProps, Event } from 'react-big-calendar';
import Paper from '@material-ui/core/Paper';
// import format from 'date-fns/format';
// import parse from 'date-fns/parse';
// import startOfWeek from 'date-fns/startOfWeek';
// import getDay from 'date-fns/getDay';
import Button from 'components/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import {
  FormControlLabel,
  FormGroup,
  Checkbox,
  CheckboxProps,
  withStyles
} from '@material-ui/core';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';

import styled from 'styled-components';

import {addMinutes} from 'date-fns';

import Api from 'Services/api';

import Calendar2 from 'components/Calendar';

// const locales = {
//   'en-US': require('date-fns/locale/en-US'),
// }
// const localizer = dateFnsLocalizer({
//   format,
//   parse,
//   startOfWeek,
//   getDay,
//   locales,
// });
import { green } from '@material-ui/core/colors';
import {calcRecurrence} from '@dsrcloud/utils';
import {Agreement} from '@dsrcloud/types';


const Toolbar: React.FC<ToolbarProps> = (props) => {

  const { label, onNavigate, view, onView} = props;

  return (
    <Grid 
      container 
      // spacing={2} 
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      
      <Grid item xs={4}>
        <Button 
          color="rose"
          round
          size="sm"
          onClick={() => {
            onNavigate('TODAY')
          }}
        >
          Today
        </Button>

        <Button 
          color="rose"
          round
          size="sm"
          onClick={() => {
            onNavigate('PREV')
          }}
        >
          Back
        </Button>

        <Button 
          color="rose"
          round
          size="sm"
          onClick={() => {
            onNavigate('NEXT')
          }}
        >
          Next
        </Button>
      </Grid>
      
      <Grid item xs={4}>
          
        <Typography style={{textAlign: 'center'}}>{label}</Typography>
      </Grid>

    <Grid item xs={4}>
      <div style={{float: 'right'}}>
        
        <Button 
          color={view === 'month' ? 'success' : 'primary'}
          round
          size="sm" 
          onClick={() => {
            onView('month')
        }}
        >
          Month
        </Button>

        <Button 
          color={view === 'week' ? 'success' : 'primary'}
          round
          size="sm" 
          onClick={() => {
            onView('week')
        }}
        >
          Week
        </Button>

        <Button 
          color={view === 'day' ? 'success' : 'primary'}
          round
          size="sm" 
          onClick={() => {
            onView('day')
        }}
        >
          Day
        </Button>

        <Button 
          color={view === 'agenda' ? 'success' : 'primary'}
          round
          size="sm" 
          onClick={() => {
            onView('agenda')
        }}
        >
          Agenda
        </Button>
      </div>
    </Grid>

    
    </Grid>

  )
}

interface MyEvent {
  title: string;
  start: Date;
  end: Date;
  allDay: boolean;
  resource?: {linkTo?: string};
}


const RedCheckbox = withStyles({
  root: {
    color: '#e91e1e',
    '&$checked': {
      color:'#e91e1e',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const GreenCheckbox = withStyles({
  root: {
    color: '#4caf50',
    '&$checked': {
      color:'#4caf50',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);


const MyCalendar: React.FC = () => {
  
  const myEventsList = [
    {title: "Bim", start: new Date(), end: new Date(), allDay: false, resource: "oi"},
    {title: "Alexandre", start: new Date(), end: new Date(), allDay: false, resource: "oi"},
    {title: "Roberto", start: new Date(), end: new Date(), allDay: false, resource: "oi"},
    {title: "Bim", start: new Date(), end: new Date(), allDay: false, resource: {color: 'danger'}},
  ];

  const [events, setEvents] = useState<Array<Event>>([]);
  const [state, setState] = React.useState({
    invoice: true,
    service: true,
    checkedF: false,
    // checkedG: true,
  });

  useEffect(() => {
    async function agreementsToEvents() {
      // let events : MyEvent[] = [];
      let events : Event[] = [];

      if(state.invoice){
        const agreements = await Api.get('/agreements');
        // console.log(agreements.data);
        
        agreements.data.forEach((agreement: any) => {
          const recurrences = calcRecurrence(agreement.billingFeatures.recurrence);
  
          recurrences.forEach( recurrence => {
            console.log(recurrence);
            const event = {
              // title: recurrence.
              title: `${agreement.identification.title}`,
              start: new Date(recurrence),
              end: new Date(addMinutes(recurrence, 10)),
              allDay: false,
              // linkTo: `/agreement/edit/${agreement.id}`,
              // color: 'danger',
              resource: {
                linkTo: `/agreement/edit/${agreement.id}`,
                color: 'danger',
              }
            };
            events.push(event);
  
          });
          
        });
        // setEvents(events);
      }


      if(state.service){
        const services = await Api.get('/agreement-services');
        console.log('services');
        console.log(services.data);

        services.data.forEach((service: any) => {
          const recurrences = calcRecurrence(service.schedule.recurrence);
  
          recurrences.forEach( recurrence => {
            console.log(recurrence);
            const event = {
              // title: recurrence.
              title: service.identification.serviceName,
              start: new Date(recurrence),
              end: new Date(addMinutes(recurrence, 10)),
              allDay: false,
              // linkTo: `/agreement/edit/${service.identification?.agreement?.id}/service/${service.id}`,
              // color: 'success',
              resource: {
                linkTo: `/agreement/edit/${service.identification?.agreement?.id}/service/${service.id}`,
                color: 'success',
              }
            };
            events.push(event);
          });
        });


      }

      setEvents(events);
      
    }
    agreementsToEvents();
  }, [state]);

  

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  // const []

  return (
    <Paper style={{padding: 3}}>
      {/* <Calendar
        localizer={localizer}
        events={myEventsList}
        startAccessor="start"
        endAccessor="end"
        style={{height: 'calc(100vh - 125px)'}}
        components={{
          toolbar: Toolbar,
          
        }}
      /> */}
      <FormGroup row>
      <FormControlLabel
        control={
          <RedCheckbox
            checked={state.invoice}
            onChange={handleChange}
            name="invoice"
            color="primary"
          />
        }
        label="Invoice"
      />
      <FormControlLabel
        control={
          <GreenCheckbox
            checked={state.service}
            onChange={handleChange}
            name="service"
            color="primary"
          />
        }
        label="Service"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.checkedF}
            onChange={handleChange}
            name="checkedF"
            color="primary"
            disabled
          />
        }
        label="Ticket"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.checkedF}
            onChange={handleChange}
            name="checkedF"
            color="primary"
            disabled
          />
        }
        label="Project"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.checkedF}
            onChange={handleChange}
            name="checkedF"
            color="primary"
            disabled
          />
        }
        label="Opportunity"
      />
      
    </FormGroup>
      <Calendar2 events={events}/>
      
    </Paper>
  )
}

export default MyCalendar;