import { createStyles, Theme } from '@material-ui/core/styles';
import { hexToRgb } from 'utils/themeUtils'; 

const styles = ( {gradientPalette, palette}: Theme) => createStyles( {
  card: {
    border: "0",
    // marginBottom: "30px",
    marginBottom: '10px',
    marginTop: "25px",
    borderRadius: "6px",
    // color: "rgba(" + hexToRgb(gradientPalette.blackColor) + ", 0.87)",
    // background: gradientPalette.whiteColor,
    width: "100%",
    boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(gradientPalette.blackColor) + ", 0.14)",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: "0",
    wordWrap: "break-word",
    fontSize: ".875rem",
  },
  cardPlain: {
    background: "transparent",
    boxShadow: "none"
  },
  cardProfile: {
    marginTop: "30px",
    textAlign: "center"
  },
  cardChart: {
    "& p": {
      marginTop: "0px",
      paddingTop: "0px"
    }
  },
  bgDarker: {
    background: palette.background.default,
    // background: '#F5FFFA'
    // "& > * > * > * > * > * > * > *": {
    //   background: palette.background.paper,
    // }
  },
});

export default styles;