import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';

// import { makeStyles } from '@material-ui/core/styles';

// import {MdClose, MdEdit, MdRemoveRedEye, MdAdd, MdDelete} from 'react-icons/md';
// import {RiAddLine} from 'react-icons/ri';
// import SvgIcon from '@material-ui/core/SvgIcon';
// import styles from './styles';
// import {Link} from 'react-router-dom';

import  { useParams } from 'react-router-dom';

import Api from 'Services/api';
// import { useForm } from "react-hook-form";
import LinearProgress from '@material-ui/core/LinearProgress';

import IdentificationBlock from './blocks/Identification';
// import AdminFeaturesBlock from './blocks/AdminFeatures';
// import BillingFeaturesBlock from './blocks/BillingFeatures';

// import OrderingCompanyBlock from './blocks/OrderingCompany';


// import ServicesTableBlock from './blocks/ServicesTable';
// import ServicesTableBlock from '../../AgreementService/AgreementServiceTable';
import AddressBlock from '../../../sharedBlocks/Address';
import TechnicalFeaturesBlock from './blocks/TechnicalFeatures';
import ScheduleBlock   from './blocks/Schedule';

import ServiceProviderCompanyBlock from './blocks/serviceProviderCompany';
import CompanyReceivingServiceBlock from './blocks/companyReceivingService';

// const useStyles = makeStyles(styles);

interface AgreementServiceParams {
  action: string;
  serviceId: string;
}

const AgreementServiceForm: React.FC = () => {
  // const classes = useStyles();

  const { action, serviceId } = useParams<AgreementServiceParams>();

  const [ agreementService, setAgreementService ] = useState<any>(undefined); 
  const [ isLoading, setIsLoading ] = useState(true);

  // async function getAgreement(){
  //   try {
  //     const response = await Api.get(`/agreement/${agreementId}`);
  //     console.log(response.data);
  //     setAgreement(response.data);
  //     setIsLoading(false);
  //   }
  //   catch(error){
  //     alert('error on get agreement');
  //   }
  // }

  useEffect(() => {
    async function getAgreementService(){
      try {
        const response = await Api.get(`/agreement-service/${serviceId}`);
        console.log(response.data);
        setAgreementService(response.data);
        setIsLoading(false);
      }
      catch(error){
        alert('error on get agreement');
      }
    }
    getAgreementService();
  }, [serviceId]);


  const onSubmit = async (data: any) => {
    // alert(JSON.stringify(data));
    // return({});
    const response = await Api.put(`/agreement-service/${serviceId}`, data);
    setAgreementService(response.data);
    return(response.data);
  };

  // const xl = 6;
  // const spaceFields = 2;
  // const fieldSize = 'small';
  const readOnly = (action === 'view') ? true: false;

  return (
    <Grid container spacing={2}>
      {isLoading ?
        <Grid item sm={12}>
          <LinearProgress />
        </Grid>
      : 
      <>
      
        <IdentificationBlock 
          readOnly={readOnly}
          defaultValues={{identification: agreementService.identification}}
          onSubmit={onSubmit}
        />

        <ServiceProviderCompanyBlock
         readOnly={readOnly}
         defaultValues={{serviceProviderCompany: agreementService.serviceProviderCompany}}
         onSubmit={onSubmit}
        />

        <CompanyReceivingServiceBlock
         readOnly={readOnly}
         defaultValues={{companyReceivingService: agreementService.companyReceivingService}}
         onSubmit={onSubmit}
        />

        <AddressBlock 
          readOnly={readOnly}
          defaultValues={{address: agreementService.address}}
          onSubmit={onSubmit}
        />

        <TechnicalFeaturesBlock 
          readOnly={readOnly}
          defaultValues={{technicalFeatures: agreementService.technicalFeatures}}
          onSubmit={onSubmit}
        />

        <ScheduleBlock 
          readOnly={readOnly}
          defaultValues={{schedule: agreementService.schedule}}
          onSubmit={onSubmit}
        />

        {/* <AdminFeaturesBlock
          readOnly={readOnly}
          defaultValues={{adminFeatures: agreementService.adminFeatures}}
          onSubmit={onSubmit}
        /> */}

        {/* <ServicesTableBlock 
          readOnly={readOnly}
          defaultValues={{services: agreement.services}}
        /> */}

        
        

      </>
      }


      

    </Grid>
  )
}

export default AgreementServiceForm;
