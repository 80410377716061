import React, {useState, useEffect} from 'react';

import  { useParams } from 'react-router-dom';

import {
  useHistory,
} from 'react-router-dom';

import Table from 'components/Table';
import Api from 'Services/api';
import { FaRegBuilding } from 'react-icons/fa';

interface AgreementServiceTableProps{
  readOnly?: boolean;
  defaultValues?: any;
}

interface AgreementParams {
  agreementId: string;
  action: string;
}

const AgreementServiceTable: React.FC<AgreementServiceTableProps> = () => {
  const history = useHistory();
  const [services, setServices] = useState([]);

  const { agreementId } = useParams<AgreementParams>();

  

  async function createAgreementService() {
    const response = await Api.post(`/agreement/${agreementId}/service`);
    // alert(JSON.stringify(response.data))
    // TODO redirect to service page
    // refresh services
    // getAgreementServices();
    const serviceId = response?.data?.id;
    history.push(`/agreement/edit/${agreementId}/service/${serviceId}`);
  }

  // async function createAccount(){
  //   const response = await Api.post('/agreement/');
  //   const id = response?.data?.id;
  //   history.push(`/account/edit/${id}`);
  // }

  // async function deleteAccount (id: string) {
  //   const response = await Api.delete(`/account/${id}`);
  //   console.log(response);
  //   getAccounts();
  // }

  async function getAgreementServices (){
    const response = await Api.get(`/agreement/${agreementId}/services`);
    // console.log(response);
    setServices(response.data);
  }

  async function deleteAgreementService (id: string) {
    const response = await Api.delete(`/agreement-service/${id}`);
    console.log(response);
    getAgreementServices();
  }

  useEffect(() => {
    async function getAgreementServices (){
      const response = await Api.get(`/agreement/${agreementId}/services`);
      // console.log(response);
      setServices(response.data);
    }
    getAgreementServices();    
  }, [agreementId]);

  return (
    <>
      <Table 
        color="rose"
        icon={<FaRegBuilding />}
        name="Services"

        columns={[
          {label: 'Name', dataKey: 'identification.serviceName'},
        ]}
        data={services}
        
        addFunction={() => {
          createAgreementService();
        }}

        deleteFunction={ (row: any) => () => {
          deleteAgreementService(row.id);
        }}

        viewFunction={ (row: any) => () => {
          history.push(`/agreement/view/${agreementId}/service/${row.id}`);
        }}

        editFunction={ (row: any) => () => {
          history.push(`/agreement/edit/${agreementId}/service/${row.id}`);
        }}

      />
    </>
  )
}

export default AgreementServiceTable;