import React from 'react';
// import { makeStyles } from "@material-ui/core/styles";
// import styles from './styles';
import {
  FormControlLabel,
  Switch
} from '@material-ui/core';
import {Control, Controller} from 'react-hook-form';
// const useStyles = makeStyles(styles);

interface ICustomSwitch {
  name: string;
  control: Control;
  defaultValue?: boolean;
  label?: string;
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
  readOnly?: boolean;
}

const CustomSwitch: React.FC<ICustomSwitch> = (props) => {
  // const classes = useStyles();

  const {
    name, 
    control,
    label,
    labelPlacement,
    readOnly,
    defaultValue,
    // ...rest
  } = props;

  return (
    <Controller
      render={props => (
        <FormControlLabel
          label={label}
          labelPlacement={labelPlacement ? labelPlacement : 'end'}
          control={
            <Switch
              {...props}
              checked={props.value}
              onChange={(event) => {props.onChange(event.target.checked)}}
              disabled={readOnly}
            />
          }
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  )
}

export default CustomSwitch;