import React, {useState, useEffect} from 'react';

import {FaHandshake} from 'react-icons/fa';
// import { makeStyles } from '@material-ui/core/styles';
// import styles from './styles';
import {useHistory} from 'react-router-dom';
import Api from 'Services/api'

import {calcNext} from '@dsrcloud/utils';

// import MyTable from 'components/Table';
// import DialogDelet from 'components/DialogDelete';

import { Tab,  RemoveRedEyeOutlined, Edit, OpenInNew, DeleteForever} from '@material-ui/icons';
import SuperTable from 'components/SuperTable';
import IconCard from 'components/IconCard';

import {Link} from 'react-router-dom';

import {Grid, IconButton} from '@material-ui/core';

import Button from 'components/Button';


import { Agreements } from '@dsrcloud/types';

import {format} from 'date-fns';

// const useStyles = makeStyles(styles);

const TableTest: React.FC = () => {

  // const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [agreements, setAgreements] = useState<Agreements>([]);

  const [isFrozen, setIsFrozen] = useState(false);

  async function getAgreements (){
    setLoading(true);
    const response = await Api.get('/agreements');
    setAgreements(response.data);
    setLoading(false);
  }

  async function createAgreement(){
    setLoading(true);
    const response = await Api.post('/agreement');
    const id = response?.data?.id;
    history.push(`/agreement/edit/${id}`);
  }

  useEffect(() => {
    getAgreements();    
  }, []);


  async function deleteAgreement (id: string) {
    setLoading(true);
    const response = await Api.delete(`/agreement/${id}`);
    console.log(response);
    getAgreements();
  }


  // {label: 'Number', dataKey: 'identification.number'},
  //         {label: 'Title', dataKey: 'identification.title'},
  //         {label: 'Managing', dataKey: 'managingCompany.identification.accountName'},
  //         {label: 'Ordering', dataKey: 'orderingCompany.identification.accountName'},
  //         {label: 'Bill To', dataKey: 'billTo.identification.accountName'},
  //         {label: 'Linked Services ', dataKey: '', render: (row: any) => () => {

  const [columns, setColumns] = useState<any[]>([
    {key: 'number', title: 'Number', dataKey: 'identification.number', resizable: true, width: 80},
    {key: 'title', title: 'Title', dataKey: 'identification.title', resizable: true, width: 300},
    {key: 'managing', title: 'Managing', dataKey: 'managingCompany.identification.accountName', resizable: true, width: 160},
    {key: 'ordering', title: 'Ordering', dataKey: 'orderingCompany.identification.accountName', resizable: true, width: 180},
    {key: 'billTo', title: 'Bill To', dataKey: 'billTo.identification.accountName', resizable: true, width: 180},
    {key: 'linkedServices', title: 'Linked Services', dataKey: '', resizable: true, width: 80, 
      cellRenderer: (data: any) => {
        return (
        <>{data.rowData?.services.length}</>
        )
      }
    },
    {key: 'nextInvoice', title: 'Next Invoice', dataKey: '', resizable: true, width: 80, 
      cellRenderer: (data: any) => {
        let nextInvoice = calcNext(data.rowData?.billingFeatures?.recurrence);
        let nextInvoiceText = '-';
        if(nextInvoice){
          nextInvoiceText = format(nextInvoice, 'MM-dd-yy');
        }
        return (
          <>
            {nextInvoiceText}
          </>
        )
      }
    },
    {key: 'amount', title: 'Amount', dataKey: '', resizable: true, width: 80, 
      cellRenderer: (data: any) => {
        return (
        <>{data.rowData?.billingFeatures?.amount ? `$ ${data.rowData?.billingFeatures?.amount}` : '-'}</>
        )
      }
    },
    {key: 'expire', title: 'Expire', dataKey: '', resizable: true, width: 80, 
      cellRenderer: (data: any) => {
        let formatedText;
            // console.log()
            try{
              if(data.rowData?.adminFeatures?.expirationDate !== null){
                formatedText = format(new Date(data.rowData?.adminFeatures?.expirationDate), 'MM-dd-yy');
              }
              else{
                formatedText = '-';
              }
            }
            catch(e){
              formatedText = '-';
            }
           
            return <>{formatedText}</>
      }
    },
    {key: 'actions', title: 'Actions', dataKey: '', resizable: false, width: 200, 
    cellRenderer: (data: any) => {
      // console.log(data);
      return (
        <>
          <IconButton
            component={Link}
            to={`/agreement/edit/${data.rowData.id}`}
            target="_blank"
          >
              <OpenInNew style={{color: '#ff9800'}} />
          </IconButton>

          <IconButton
            component={Link}
            to={`/agreement/view/${data.rowData.id}`}
          >
              <RemoveRedEyeOutlined style={{color: '#00acc1'}} />
          </IconButton>

          <IconButton
            component={Link}
            to={`/agreement/edit/${data.rowData.id}`}
          >
              <Edit style={{color: '#4caf50'}} />
          </IconButton>

          <IconButton
            onClick={() => {
              if(data.rowData?.id){
                deleteAgreement(data.rowData?.id);
              }
            }}
          >
            <DeleteForever color="error" />
          </IconButton>
  
        </>
      )
    }},
  ]);

  const tableHeight = loading ? 204 : 200;

  const frozen = () => {
    if(!isFrozen){
      const newColumns = columns.map(col => {
        if(col.key === 'number' || col.key === 'title'){
          return {...col, frozen: 'left'};
        }
        else{
          return col;
        }
      });
      setColumns(newColumns);
      setIsFrozen(true);
    }
    else{
      const newColumns = columns.map(col => {
        if(col.key === 'number' || col.key === 'title'){
          let newCol = {...col};
          if(newCol.frozen){
            delete newCol.frozen;
          }
          
          return newCol;
        }
        else{
          return col;
        }
      });
      setColumns(newColumns);
      setIsFrozen(false);
    }
    
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <IconCard 
            icon={<FaHandshake />}
            iconColor="rose"
            title="Agreement"
            margin={false}
            loading={loading}
            addFunction={createAgreement}
            buttons={
              <>
                <Button 
                  color="primary" 
                  justIcon
                  round
                  aria-label="add"
                  onClick={() => frozen()}
                  disabled={loading}
                  // className={classes.margin}
                >
                  <Tab />
                </Button>
              </>
            }
          >
            <div 
              style={{ 
                maxWidth: '100%', 
                height: `calc(100vh - ${tableHeight}px)`, 
                minHeight: '250px'}}
            >
              <SuperTable 
                columns={columns}
                data={agreements}
              />
            </div>
          </IconCard>
        </Grid>
      </Grid>
    </>
  )
}

export default TableTest;
